import React from "react";

import { Row, Input, Col, Card, CardBody, Button } from "reactstrap";
import { useMyAdresses } from "@Components/Hooks/AddressHooks";

const AddressesTab = ({ onAddAddress }) => {
  const headers = [
    { text: "Nombre", value: "name" },
    { text: "Teléfono", value: "phone" },
    { text: "Teléfono 2", value: "phone2" },
    { text: "Dirección", value: "address" },
    { text: "Colonia", value: "colony" },
    { text: "Ciudad", value: "city" },
    { text: "Estado", value: "state" },
    { text: "Principal", value: "principal" },
    { text: "Acciones", value: "actions" },
  ];

  const { addressesList } = useMyAdresses();

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Row className="align-items-center justify-content-between my-2">
            <Col xs={12} sm={4}>
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control"
                  aria-label="Search by address"
                  aria-describedby="addressSearcher"
                  placeholder="Buscar dirección"
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  id="addressSearcher"
                >
                  <i className="la la-search"></i>
                </button>
              </div>
            </Col>
            <Col xs={12} sm={3} md={2}>
              <button
                className="btn btn-primary my-2 d-flex align-items-center justify-content-center"
                type="button"
                style={{ width: "100%" }}
                onClick={onAddAddress}
              >
                <i className="la la-plus mx-2 fs-4"></i>
                Agregar dirección
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {addressesList.length ? (
                    (addressesList || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td
                            key={header.value + "-" + index}
                            style={{
                              width: header.value == "actions" ? "10%" : "auto",
                            }}
                          >
                            {header.value == "actions" ? (
                              <div className="d-flex justify-content-center">
                                <Button className="mx-1" size="sm" outline>
                                  <i className="la la-pen"></i>
                                </Button>
                                <Button color="primary" size="sm" outline>
                                  <i className="la la-trash"></i>
                                </Button>
                              </div>
                            ) : (
                              item[header.value]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No hay registros existentes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default AddressesTab;
