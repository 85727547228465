import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Input, Col, Card, CardBody } from "reactstrap";

const SimsInventory = () => {
  document.title = "Inventario de SIMS | Panda Móvil";
  const headers = [
    { text: "Cliente", value: "user_name" },
    { text: "Nombre", value: "first_name" },
    { text: "Acciones", value: "actions" },
  ];

  const simsInventory = [
    {
      id: 47,
      first_name: "Carole",
      last_name: "Stonebridge",
      user_name: "cstonebridge1a",
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Inventario de SIMS</h1>
        <Row>
          <Col xs={12}>
            <Row className="d-flex align-items-center justify-content-between my-2">
              <Col xs={12} sm={4}>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by username"
                    aria-describedby="usernameSearcher"
                    placeholder="Buscar..."
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="usernameSearcher"
                  >
                    <i className="la la-search"></i>
                  </button>
                </div>
              </Col>
              <Col xs={12} sm={3} className="text-end">
                <Link
                  className="btn btn-outline-primary d-flex justify-content-center my-2"
                  style={{ width: "100%" }}
                  to={`/reportes/sims/resumen-de-sims`}
                >
                  Ver resumen
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <div className="fs-6 my-3">
            Registros: <b>{simsInventory.length}</b>
          </div>
        </Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-responsive table-card">
                <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      {headers.map((header) => (
                        <th key={header.value} scope="col">
                          {header.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {simsInventory.length ? (
                      (simsInventory || []).map((item, index) => (
                        <tr key={index}>
                          {headers.map((header) => (
                            <td
                              key={header.value + "-" + index}
                              style={{
                                width:
                                  header.value == "actions" ? "10%" : "auto",
                              }}
                            >
                              {header.value == "actions" ? (
                                <Link
                                  className="btn btn-primary"
                                  to={`/inventario-de-sims/sims/${item.user_name}`}
                                >
                                  Ver mis SIMS
                                </Link>
                              ) : (
                                item[header.value]
                              )}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%" className="text-center">
                          No hay registros existentes
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default SimsInventory;
