export const allRegimens = [
  {
    value: "601",
    label: "General de Ley Personas Morales",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "603",
    label: "Personas Morales con Fines no Lucrativos",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "620",
    label:
      "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "622",
    label: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "623",
    label: "Opcional para Grupos de Sociedades",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "624",
    label: "Coordinados",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "606",
    label: "Arrendamiento",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "612",
    label: "Personas Físicas con Actividades Empresariales y Profesionales",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "625",
    label:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "621",
    label: "Incorporación Fiscal",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "626",
    label: "Régimen Simplificado de Confianza (RESICO)",
    use: [
      {
        value: "G01",
        label: "Adquisición de mercancías.",
      },
      {
        value: "G03",
        label: "Gastos en general.",
      },
      {
        value: "I06",
        label: "Comunicaciones telefónicas.",
      },
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "605",
    label: " Sueldos y Salarios e Ingresos Asimilados a Salarios",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "607",
    label: "Régimen de Enajenación o Adquisición de Bienes",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "608",
    label: "Demás ingresos",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "611",
    label: "Ingresos por Dividendos (socios y accionistas)",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "614",
    label: "Ingresos por intereses",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "615",
    label: "Régimen de los ingresos por obtención de premios",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "616",
    label: "Sin obligaciones fiscales",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
  {
    value: "610",
    label:
      "Residentes en el Extranjero sin Establecimiento Permanente en México",
    use: [
      {
        value: "S01",
        label: "Sin efectos fiscales.",
      },
      {
        value: "CP01",
        label: "Pagos",
      },
    ],
  },
];

export const dataDropDownFormaPago = [
  { value: "1", label: "01 Efectivo" },
  { value: "3", label: "03 Transferencia electrónica de fondos" },
  { value: "4", label: "04 Tarjeta de Crédito" },
  { value: "5", label: "05 Monedero Electrónico" },
  { value: "6", label: "06 Dinero Electrónico" },
  { value: "18", label: "28 Tarjeta de Débito" },
  { value: "21", label: "31 Intermediario pagos" },
  { value: "22", label: "99 Por definir" },
];
