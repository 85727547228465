import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { groupBy } from "lodash";
import { products } from "../dummy/products";
import { ProductCard } from "./ProductCard";
import classNames from "classnames";
import ProductDetailsModal from "./ProductDetailsModal";
import { useCartResume } from "@Components/Hooks/ShoppingCartHooks";

const CatalogueTab = () => {
  const { cartProducts } = useCartResume();
  const categories = [
    {
      qty: 4,
      name: "SIM Card",
    },
    {
      qty: 1,
      name: "MiFi",
    },
    {
      qty: 1,
      name: "Router",
    },
  ];
  const [currentCategory, setCurrentCategory] = useState(null);
  const groupedProducts = groupBy(products, "type");

  const generateIdFromName = (categoryName) => {
    return categoryName.replace(/\s+/g, "-").toLowerCase();
  };

  const scroll = (id) => {
    const category = document.querySelector(`#${id}`);
    const { left, top } = category.getBoundingClientRect();
    const negativeY = 80;
    window.scrollTo(left, top + window.scrollY - negativeY);
  };

  const onSelectCategory = (categoryName) => {
    const id = generateIdFromName(categoryName);
    setCurrentCategory(id);
    scroll(id);
  };

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isOpenProductDetails, setIsOpenProductDetails] = useState(false);

  const onOpenProduct = (product) => {
    setSelectedProduct(product);
    setIsOpenProductDetails(true);
  };
  return (
    <React.Fragment>
      <Row>
        <Col xs={5} sm={3} md={2}>
          <Link
            className="btn btn-primary py-4 d-flex justify-content-center"
            style={{ width: "100%" }}
            to="/marketplace/carrito"
          >
            <i className="la la-shopping-cart" style={{ fontSize: "3rem" }}></i>
            <div className="fs-6">
              Mi carrito
              <br />({cartProducts.length})
            </div>
          </Link>

          <div className="fs-4 text-primary">Categorías</div>
          {categories.map((category) => (
            <div
              key={category.name}
              onClick={() => onSelectCategory(category.name)}
              className={classNames("cursor-pointer", {
                "text-primary":
                  generateIdFromName(category.name) == currentCategory,
              })}
            >
              {category.name} ({category.qty})
            </div>
          ))}
        </Col>
        <Col xs={7} sm={9} md={10} lg={9} className="mx-auto">
          <div className="fs-3 my-4 text-primary">Catálogo de productos</div>
          {categories.map((category) => (
            <Row id={generateIdFromName(category.name)} key={category.name}>
              <Col className="col-12 fs-5 my-2">{category.name}</Col>
              {groupedProducts[category.name].map((product) => (
                <Col key={product._id} xs={12} sm={6} md={6} lg={4}>
                  <ProductCard
                    product={product}
                    onSelectProduct={onOpenProduct}
                  />
                </Col>
              ))}
            </Row>
          ))}
          {isOpenProductDetails && selectedProduct && (
            <ProductDetailsModal
              isOpen={isOpenProductDetails}
              product={selectedProduct}
              onClose={() => setIsOpenProductDetails(false)}
              onAddToCart={() => {}}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CatalogueTab;
