import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import PurchasesTabs from "./Components/PurchasesTabs";
import BillingTabs from "./Components/BillingTabs";
import { ProductsProvider } from "../../Components/Hooks/ProductsProvider";
import { useEffect } from "react";
import ModalPayments from "./ModalPayments";
import ProductCards from "./ProductCards";

const Purchases = () => {
  document.title = "Compras | Panda Móvil";

  const tabsList = [
    { text: "Planes Xpress", value: "XPRESS" },
    { text: "Planes Mensuales", value: "MENSUAL" },
    { text: "Planes Anuales", value: "ANUAL" },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [pillTab, setPillTab] = useState(tabsList[0]);
  const [data, setData] = useState([]);
  const [ancho, setAncho] = useState(0);

  const { getAllProducts, products } = ProductsProvider();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const togglePillTab = (tab) => {
    if (pillTab.value !== tab.value) {
      setPillTab(tab);
      setActiveIndex(0);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    let array = [];
    let arrayData = [];

    if (window.matchMedia("(max-width: 600px)").matches) {
      // Código para pantallas con un ancho máximo de 600px
      if (products.length) {
        const filter = products.filter((e) => e.name.includes(pillTab.value));
        for (let i = 0; i < filter.length; i++) {
          array = [...array, filter[i]];

          if ((i + 1) % 1 === 0) {
            arrayData.push(array);
            array = [];
          } else if (i + 1 == filter.length) {
            arrayData.push(array);
          }
        }

        setData(arrayData);
      }
    } else {
      if (products.length) {
        const filter = products.filter((e) => e.name.includes(pillTab.value));
        for (let i = 0; i < filter.length; i++) {
          array = [...array, filter[i]];

          if ((i + 1) % 3 === 0) {
            arrayData.push(array);
            array = [];
          } else if (i + 1 == filter.length) {
            arrayData.push(array);
          }
        }

        setData(arrayData);
      }
      // Código para pantallas con un ancho mayor a 600px
    }
  }, [pillTab, products]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Recargas</h1>
          <Card>
            <CardBody>
              <Nav pills className="nav-justified mb-1 align-items-center">
                {tabsList.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: pillTab.value === tab.value,
                      })}
                      onClick={() => {
                        togglePillTab(tab);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </CardBody>
          </Card>

          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={data}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              style={{ color: "red", important: "true" }}
            />

            {data.map((e) => (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={""}
              >
                <div className="d-flex gap-4 justify-content-center py-4">
                  {e.map((i) => (
                    <ProductCards e={i} key={e.id} pillTab={pillTab} />
                  ))}
                </div>
              </CarouselItem>
            ))}

            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Purchases;
