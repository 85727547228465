import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Input, Col, Card, CardBody, Label } from "reactstrap";
import ExportDropdown from "@Components/UI/ExportDropdown";
import PrintButton from "@Components/UI/PrintButton";

const UserSims = () => {
  const { username } = useParams();
  const navigate = useNavigate();

  document.title = `SIMS de ${username} | Panda Móvil`;
  const headers = [
    { text: "Producto", value: "producto" },
    { text: "ICCID", value: "iccid" },
    { text: "Teléfono", value: "teléfono" },
    { text: "IMSI", value: "imsi" },
    { text: "Estado", value: "estado" },
    { text: "QR", value: "qr" },
  ];

  const fetchedUser = {
    id: 47,
    first_name: "Carole",
    last_name: "Stonebridge",
    user_name: "cstonebridge1a",
  };

  const simsInventory = [
    {
      producto: "Movilidad",
      iccid: "8952140062158620315F",
      teléfono: "5650210428",
      imsi: "334140168824400",
      estado: "No activado",
      qr: "No aplica",
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <h1>SIMS de {fetchedUser.user_name}</h1>
        <div className="d-flex">
          <div
            className="text-primary"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          >
            Regresar
          </div>
          <div className="mx-1">/</div>
          Ver SIMS
        </div>
        <Row>
          <Col xs={12}>
            <Row className="d-flex align-items-center justify-content-between my-2">
              <Col xs={12} sm={12} md={4}>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by ICCID"
                    aria-describedby="ICCIDSearcher"
                    placeholder="Buscar por ICCID"
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="iccidSearcher"
                  >
                    <i className="la la-search"></i>
                  </button>
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} className="">
                <Row className="align-items-end">
                  <Col xs={12} sm={3}>
                    <Label className="form-label">Producto</Label>
                    <select className="form-select form-select-sm" value={1}>
                      <option value> Seleccione un producto</option>
                      <option defaultValue={1}> Movilidad</option>
                      <option defaultValue={2}> ESIM</option>
                      <option defaultValue={3}> HBB</option>
                      <option defaultValue={4}> MIFI</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={3}>
                    <Label className="form-label">Registros</Label>
                    <select className="form-select form-select-sm">
                      <option value>
                        Seleccione una cantidad de registros
                      </option>
                      <option defaultValue={1}> 10</option>
                      <option defaultValue={2}> 25</option>
                      <option defaultValue={3}> 50</option>
                      <option defaultValue={4}> 100</option>
                      <option defaultValue={5}> 250</option>
                      <option defaultValue={6}> 500</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={3}>
                    <Label className="form-label">Filtrar por activados</Label>
                    <select className="form-select form-select-sm">
                      <option value> Sin filtros</option>
                      <option defaultValue={1}> Activos</option>
                      <option defaultValue={2}> Inactivos</option>
                      <option defaultValue={3}> Todos</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={3}>
                    <Label className="form-label">
                      Filtrar por disponibilidad
                    </Label>
                    <select className="form-select form-select-sm">
                      <option value> Sin filtros</option>
                      <option defaultValue={1}> Todos los registros</option>
                      <option defaultValue={2}> Disponibles</option>
                      <option defaultValue={3}> No disponibles</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} sm={6} md={8} className="fs-6">
            Registros: <b>{simsInventory.length}</b>
          </Col>
          <Col xs={12} sm={6} md={4} className="text-end">
            <Row className="my-2">
              <Col className="col-6">
                <ExportDropdown />
              </Col>
              <Col className="col-6">
                <PrintButton />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-responsive table-card">
                <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      {headers.map((header) => (
                        <th key={header.value} scope="col">
                          {header.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {simsInventory.length ? (
                      (simsInventory || []).map((item, index) => (
                        <tr key={index}>
                          {headers.map((header) => (
                            <td key={header.value + "-" + index}>
                              {item[header.value]}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%" className="text-center">
                          No hay registros existentes
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default UserSims;
