import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  response: null,
  error: "", // for error message
  loading: false,
  errorMsg: false, // for error
};

const queryUFSlice = createSlice({
  name: "queryUF",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.errorMsg = true;
    },
    setResponse(state, action) {
      state.response = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setLoading(state, action) {
      state.loading = true;
      state.response = null;
    }
  },
});

export const { apiError, setResponse, setLoading } = queryUFSlice.actions;

export default queryUFSlice.reducer;
