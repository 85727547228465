import { Row, Input, Col, Form, FormText, FormGroup, Label, Button, Spinner, Alert } from "reactstrap";
import { useOfferings } from './useOfferings';
import { usePreRegister } from './usePreRegister';

const PreRegisterForm = () => {
  const { preRegisterForm } = usePreRegister();
  const { filteredOfferings, setPeriod, loading } = useOfferings();

  return (<>
    <Form onSubmit={e => {
      e.preventDefault();
      preRegisterForm.handleSubmit();
    }}>
      <Row>
        <Col xs={12} sm={6}>
            <FormGroup>
            <Label for="pre-registration-msisdn">Número</Label>
            <Input id="pre-registration-msisdn"
                onChange={preRegisterForm.handleChange}
                onBlur={preRegisterForm.handleBlur}
                invalid={
                  preRegisterForm.touched.msisdn &&
                  !!preRegisterForm.errors.msisdn
                }
                value={preRegisterForm.values.msisdn || ''}
                name="msisdn"
                placeholder="4455667788"
                type="tel"
            />
            <FormText>Número perteneciente a Pandamovil</FormText>
            </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
            <FormGroup>
            <Label for="pre-registration-ported">Número entrante</Label>
            <Input id="pre-registration-ported"
                onChange={preRegisterForm.handleChange}
                onBlur={preRegisterForm.handleBlur}
                invalid={
                  preRegisterForm.touched.msisdnPorted &&
                  !!preRegisterForm.errors.msisdnPorted
                }
                value={preRegisterForm.values.msisdnPorted || ''}
                name="msisdnPorted"
                placeholder="4455667788"
                type="tel"
            />
            <FormText>Número entrante o a migrar</FormText>
            </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
            <FormGroup>
            <Label for="pre-registration-offer-id-form-filter">Periodo</Label>
            <Input id="pre-registration-offer-id-form-filter"
                defaultValue={""}
                onInput={e => setPeriod(e.target.value)}
                name="msisdn"
                type="select"
            >
                <option value="" disabled>Periodo</option>
                <option value={'7d'}>7 días</option>
                <option value={'15d'}>15 días</option>
                <option value={'30d'}>30 días</option>
                <option value={'12m'}>12 meses</option>
            </Input>
            </FormGroup>
        </Col>
        <Col xs={12} sm={6}>
            <FormGroup>
            { loading && <Spinner size="sm" className="me-2" /> }
            <Label for="pre-registration-offer-id-form">Oferta</Label>
            { !loading && filteredOfferings &&
                <Input id="pre-registration-offer-id-form"
                onChange={preRegisterForm.handleChange}
                onBlur={preRegisterForm.handleBlur}
                invalid={
                  preRegisterForm.touched.offeringId &&
                  !!preRegisterForm.errors.offeringId
                }
                value={preRegisterForm.values.offeringId || ''}
                name="offeringId"
                type="select"
                >
                <option value="" disabled>Seleccionar oferta</option>
                { filteredOfferings.map(offering =>
                    <option key={offering.offer_id} value={offering.offer_id}>{ offering.display_name }</option>)
                }
                </Input>
            }
            </FormGroup>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Button type="button" color="primary" onClick={() => preRegisterForm.submitForm()}>Pre-registrar</Button>
        </Col>
      </Row>
    </Form>
  </>);
};

export default PreRegisterForm;