import { useEffect, useState } from "react";

const fetchMyAdresses = () => {
  return [
    {
      id: 1,
      name: "Alejandro",
      phone: "1234567890",
      phone2: "1234567890",
      address: "Address number one",
      colony: "the colony",
      city: "the city",
      state: "the state",
      principal: "-",
    },
  ];
};

const useMyAdresses = () => {
  const [addressesList, setAddressesList] = useState([]);

  useEffect(() => {
    const fetchedAddresses = fetchMyAdresses();
    setAddressesList(fetchedAddresses);
  }, []);
  return { addressesList };
};

export { useMyAdresses };
