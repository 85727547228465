import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { authProvider } from "../../../../Components/Hooks/authProvider";
import DeleteDeviceModal from "./DeleteDeviceModal";

export const ApplicationsTable = () => {
  const headers = [
    { text: "Telefono", value: "msisdn" },
    { text: "Acciones", value: "actions" },
  ];

  const items = [
    {
      id: 1,
      dispositivo: "PC APP WEB",
      identificador: null,
      nip: null,
      estatus: true,
    },
    {
      id: 2,
      dispositivo: "MOVIL ANDROID	",
      identificador: null,
      nip: null,
      estatus: true,
    },
  ];

  const { phones } = authProvider();

  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [isOpenDeleteDeviceModal, setIsOpenDeleteDeviceModal] = useState(false);
  const onDeleteDevice = (device) => {
    console.log(device);
    setDeviceToDelete(device);
    setIsOpenDeleteDeviceModal(true);
  };

  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                <th scope="col">Telefono</th>
                <th scope="col" className="text-center">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              {phones.length ? (
                (phones || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item.msisdn}</td>
                    <td align="center">
                      <button
                        className="btn btn-primary"
                        onClick={() => onDeleteDevice(item)}
                      >
                        Quitar de mi lista
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isOpenDeleteDeviceModal && deviceToDelete && (
            <DeleteDeviceModal
              isOpen={isOpenDeleteDeviceModal}
              device={deviceToDelete}
              onClose={() => setIsOpenDeleteDeviceModal(false)}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};
