import React from "react";
import { Modal, ModalBody } from "reactstrap";
import DataTableCard from "@Components/UI/DataTableCard";

import { Row, Input, Col, Label } from "reactstrap";

const ProductListModal = ({
  isOpen,
  purchaseId,
  onClose,
  fetchedProducts = [
    {
      producto: "Movilidad",
      iccid: "8952140062158620315F",
      teléfono: "5650210428",
      imsi: "334140168824400",
      estado: "No activado",
      qr: "No aplica",
    },
  ],
}) => {
  const headers = [
    { text: "Producto", value: "producto" },
    { text: "ICCID", value: "iccid" },
    { text: "Teléfono", value: "teléfono" },
    { text: "IMSI", value: "imsi" },
    { text: "Estado", value: "estado" },
    { text: "QR", value: "qr" },
  ];

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={isOpen}
        toggle={() => {
          onClose();
        }}
        className="modal-fullscreen"
        id="exampleModalFullscreen"
      >
        <ModalBody>
          <Row className="w-100 modal-title text-primary mb-4">
            <Col className="col-10 fs-1-5">
              Productos de la orden {purchaseId}
            </Col>
            <Col className="col-2 text-end">
              <i
                className="la la-times-circle cursor-pointer text-primary fs-1-5"
                onClick={onClose}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row className="d-flex align-items-center justify-content-between my-2">
                <Col xs={12} sm={4}>
                  <Label className="form-label text-primary">Nombre</Label>
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by name"
                    aria-describedby="nameSearcher"
                    placeholder="Buscar por nombre del producto"
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <Label className="form-label text-primary">ICCID</Label>
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by ICCID"
                    aria-describedby="ICCIDSearcher"
                    placeholder="Buscar por ICCID"
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <Label className="form-label text-primary">Teléfono</Label>
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by phone"
                    aria-describedby="phoneSearcher"
                    placeholder="Buscar por # de teléfono"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} sm={6} md={8} className="fs-6">
              Total de productos: <b>{fetchedProducts.length}</b>
            </Col>
          </Row>
          <Col>
            <DataTableCard
              addCounterCol
              headers={headers}
              items={fetchedProducts}
            />
          </Col>
        </ModalBody>
        {/* <div className="modal-footer">
        </div> */}
      </Modal>
    </React.Fragment>
  );
};

export default ProductListModal;
