import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const Gauge = ({ labels, data, name }) => {
  const dataset = {
    labels,
    datasets: [{
      data, 
      backgroundColor: [
        '#5c666e',
        '#ec3144',
        '#b62334',
        '#851824',
        '#3a4650',
        '#e7e6e6'
      ]
    }]
  };

  const config = {
    locale:'en-US',
    circumference: 180,
    rotation: -90,
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return <Doughnut data={dataset} options={config} />;
};

export default Gauge;