import { getStatus } from '../../helpers/fakebackend_helper';
import { useDispatch, useSelector } from "react-redux";
import { apiError, setResponse, setLoading } from './reducer';
import { createSelector } from '@reduxjs/toolkit';

export const useQueryUF = () => {
  const dispatch = useDispatch();
  const getMSISDNStatus = async (msisdn) => {
    try {
        dispatch(setLoading());
        const response = await getStatus(msisdn);

        if (response && response.status === 200) {
            dispatch(setResponse(response));
        } else {
            dispatch(apiError(response));
        }
    } catch (error) {
        dispatch(apiError(response));
    }
  };

  const statusData = createSelector(s => s, (state) => {
    const queryUf = state.QueryUF;
    const response = queryUf?.response;

    return ({
      statusDetails: response ? getDetails(response.result.details, response.offername) : null,
      gaugeData: response ? formatGaugeValues(response.result.total) : null,
      error: queryUf.error,
      loading: queryUf.loading,
      errorMsg: queryUf.errorMsg,
    });
});
  const { statusDetails, gaugeData, error, loading, errorMsg } = useSelector(statusData);

  return { getMSISDNStatus, statusDetails, loading, gaugeData };
};

/**
 * @typedef {Object} PlanDetail
 * @property {Object[]} sms Desgloce de SMS
 * @property {Object} totalSMS Total del desgloce SMS
 * @property {Object[]} min Desgloce de minutos
 * @property {Object} totalMin Total del desgloce minutos
 * @property {Object[]} data Desgloce de datos
 * @property {Object} totalData Total del desgloce datos
 * @property {string} offeringId Id de plan
 * @property {string} effectiveDate Fecha de inicio
 * @property {string} expireDate Fecha de expiración
 * @property {string} title Nombre del plan
 */

/**
 * Da formato a los planes de la respuesta
 * @param {Object} plans Planes del usuario {[planId]: {...desgloce}}
 * @param {*} offernames Objeto con los nombres de los planes {[planId]: "nombre"}
 * @returns {PlanDetail[]} Colección de desgloce de consumo a partir de planes
 */
const getDetails = (plans, offernames) => {
  const plansInfo = Object.keys(plans).map(planKey => plans[planKey]);

  return plansInfo.map(plan => {
    const detailInfo = getDetail(plan);
    detailInfo.title = offernames[detailInfo.offeringId];
    return detailInfo;
  });
};

/**
 * Da formato a un plan
 * @returns {PlanDetail} Desgloce de consumo de un plan
 */
const getDetail = (planBreakdown) => {
  const sms = [];
  const min = [];
  const data = [];

  const details = Object.keys(planBreakdown)
    .filter(key => key !== 'total')
    .map(planDetailKey => planBreakdown[planDetailKey]);

  const offeringId = details.map(d => d.offeringId).find(d => d !== undefined);
  const effectiveDate = details.map(d => d.effectiveDate).find(d => d !== undefined);
  const expireDate = details.map(d => d.expireDate).find(d => d !== undefined);

  details.forEach(detail => {
    if (detail.description.search('SMS ') === 0) {
      sms.push(detail);
    }
    if (detail.description.search('Minutos ') === 0) {
      min.push(detail);
    }
    if (detail.description.search('Datos ') === 0) {
      data.push(detail);
    }
  });

  const totalSMS = calculateTotal(sms);
  const totalMin = calculateTotal(min);
  const totalData = calculateTotal(data);

  return { offeringId, effectiveDate, expireDate, sms, min, data, totalSMS, totalMin, totalData };
};

/**
 * Da formato para la gráfica gauge 
 * @param {Object} totalAmounts 
 */
const formatGaugeValues = (totalAmounts) => {
  const descriptionMappings = {
    'Datos Velocidad Best Effort': 1,
    'Datos Velocidad Best Effort.': 2,
    'Datos Roaming USA Canadá': 3,
    'Redes Sociales': 4,
    'Datos a velocidad 1 mbps': 5,
    'Datos a velocidad 512 kbps': 6,
  };

  const totals = Object.keys(totalAmounts)
    .filter(k => k.toLowerCase().startsWith('fu_rs_') ||
      k.toLowerCase().startsWith('fu_data_') ||
      k.toLowerCase().startsWith('freedata_') ||
      k.toLowerCase().startsWith('fu_thrmbb'));

  let dataR = new Array(totals.length);
  let labelR = new Array(totals.length);
  const totalR = [];
  let consumed = 0;

  totals.map(key => totalAmounts[key]).forEach(total => {
    const pos = descriptionMappings[total.description]
    if (pos) {
      consumed += parseFloat(total.totalAmt) - parseFloat(total.unusedAmt);
      dataR[pos] = parseFloat(total.unusedAmt).toFixed(2);
      labelR[pos] = total.description; 
      totalR.push(parseFloat(total.unusedAmt).toFixed(2));
    }
  });

  const total = totalR.reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0);
  dataR[0] = consumed.toFixed(2);
  labelR[0] = 'Consumido';
  dataR = dataR.filter(Boolean);
  labelR = labelR.filter(Boolean);
  return {
    dataR,
    labelR,
    gaugeLabel: total + 'Mb'
  }
};

function calculateTotal(arr) {
  return arr.reduce((total, current) => {
    total.unusedAmt += Number(current.unusedAmt);
    total.usedAmt += Number(current.usedAmt);
    total.initialAmt += Number(current.initialAmt);
    return total;
  }, { unusedAmt: 0, usedAmt: 0, initialAmt: 0 });
}
