import React from "react";
import { Navigate, Route } from "react-router-dom";

import { useLoggedIn } from "../Components/Hooks/useLoggedIn";

const AuthProtected = (props) => {
  const { loading, isLoggedIn } = useLoggedIn();

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!loading && !isLoggedIn) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
