import React from "react";
import { Input, Card, CardBody } from "reactstrap";
export const CommissionsTable = ({ disabled = false }) => {
  const headers = [
    { text: "Nombre", value: "nombre" },
    { text: "Comisión", value: "comision" },
    { text: "Estatus", value: "estatus" },
  ];

  const items = [
    { nombre: "Usuario recarga contigo", comision: 0, max: 15, estatus: true },
    { nombre: "Axios linea nueva", comision: 0, max: 15, estatus: true },
    { nombre: "Axios portabilidad", comision: 0, max: 15, estatus: true },
    {
      nombre: "Usuario recarga en canal externo",
      comision: 0,
      max: 15,
      estatus: true,
    },
    { nombre: "Auto recarga (app Axios)", comision: 0, max: 10, estatus: true },
    {
      nombre: "Usuario recarga en plataformas",
      comision: 0,
      max: 10,
      estatus: true,
    },
    {
      nombre: "Recarga a usuario que no activaste",
      comision: 0,
      max: 15,
      estatus: true,
    },
    {
      nombre: "Internet Hogar Linea nueva",
      comision: 0,
      max: 15,
      estatus: true,
    },
  ];
  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                {headers.map((header) => (
                  <th key={header.value} scope="col">
                    {header.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                (items || []).map((item, index) => (
                  <tr key={index}>
                    {headers.map((header) => (
                      <td key={header.value + "-" + index}>
                        {header.value == "comision" ? (
                          disabled ? (
                            <div>{item.comision}%</div>
                          ) : (
                            <div>
                              <Input
                                type="number"
                                className="form-control"
                                id="userInput"
                                value={item.comision}
                                onInput={(e) =>
                                  (item.comision = e.target.value)
                                }
                              />
                              <div id="passwordHelpBlock" className="form-text">
                                Max. {item.max}%
                              </div>
                            </div>
                          )
                        ) : header.value == "estatus" ? (
                          <div className="form-check form-switch form-switch-lg mx-2">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              disabled={disabled}
                              checked={item.estatus}
                            />
                          </div>
                        ) : (
                          item[header.value]
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};
