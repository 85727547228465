import React from "react";
import axios from "axios";
import { api } from "../../config";
import { successAlert, errorAlert } from "../../helpers/alerts";
import { useEffect } from "react";
import { useState } from "react";

const authProvider = () => {
  const [phones, setPhones] = useState([]);
  const [historial, setHistorial] = useState([]);
  const [orderLines, setOrderLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subs, setSubs] = useState([]);

  const getAllPhones = async () => {
    try {
      const response = await axios.get(`${api.API_URL}/app/listPhones`);

      setPhones(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPhones();
  }, []);

  const getSubs = async (phone) => {
    try {
      const response = await axios.get(
        `${api.API_URL}/app/getSubs?msisdn=${phone}`
      );

      setSubs(response.stripe);
    } catch (error) {
      console.log(error);
    }
  };

  const getHistorial = async (phone) => {
    setLoading(true);
    try {
      const response = await axios.post(`${api.API_URL}/app/listSales`, {
        msisdn: phone,
      });

      if (response.status == 200) {
        setOrderLines(response.orderLines);
        const arrayResultado = [];

        for (const año in response.result) {
          if (response.result.hasOwnProperty(año)) {
            const meses = [];

            for (const mes in response.result[año]) {
              if (response.result[año].hasOwnProperty(mes)) {
                meses.push({
                  mes: mes,
                  datos: response.result[año][mes],
                });
              }
            }

            arrayResultado.unshift({
              año: año,
              meses: meses,
            });
          }
        }

        setHistorial(arrayResultado);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const updateUser = async (value) => {
    try {
      const response = await axios.post(
        `${api.API_URL}/app/updateUserInfo`,
        value
      );

      if (response.status == 200) {
        successAlert("Actualización", response.result);
      }
    } catch (error) {
      errorAlert("Error", "Ocurrio un error al actualizar la información");
      console.log(error);
    }
  };

  const changePassword = async (value) => {
    try {
      const response = await axios.post(
        `${api.API_URL}/app/updatePassword`,
        value
      );

      if (response.status == 200) {
        successAlert("Contraseña", response.result);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.result?.description;
      errorAlert("Error", errorMessage);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const getMetrics = async (msisdn) => {
    setIsLoading(true);

    try {
      const response = await axios
        .post(`${api.API_URL}/app/getStatusMsisdn`, { msisdn })
        .catch(function (error) {
          setIsLoading(false);

          if (error.response) {
            console.log("error1");
            console.log(error.response);
            const regex = /(<([^>]+)>)/gi;
            const errorDef =
              "errors" in error.response.data
                ? error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ][0]
                : "error" in error.response.data
                ? error.response.data.error
                : "result" in error.response.data &&
                  typeof error.response.data.result == "object" &&
                  "description" in error.response.data.result
                ? error.response.data.result.description
                : error.response.data.result.replace(regex, "");
            Alert.alert("Error", errorDef, [
              // {text: 'OK', onPress: () => console.log('OK Pressed')},
              { text: "OK" },
            ]);
            return false;
          } else if (error.request) {
            console.log("error2");
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request ?? "");
            return false;
          } else {
            console.log("error3");
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message ?? "");
            return false;
          }
        });
      setIsLoading(false);
      if (response && response.status == 200) {
        return response;
      }
    } catch (error) {
      return false;
      const errorMessage = error.response?.data?.result?.description;
      throw new Error(errorMessage);
    }
  };

  return {
    updateUser,
    changePassword,
    getAllPhones,
    phones,
    getHistorial,
    historial,
    orderLines,
    loading,
    getSubs,
    subs,
    getMetrics,
    isLoading,
  };
};

export { authProvider };
