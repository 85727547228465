import { useEffect, useState } from "react";

const fetchCartResume = () => {
  return {
    productsQty: 6,
    productsTotal: 0,
    shippingCost: 120,
    cartTotal: 120,
    totalAfterPayment: 880,
  };
};

const fetchCartProducts = () => {
  return [
    {
      pictures: [
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      ],
      _id: "636eb00c7ea143001d126742",
      type: "SIM Card",
      brand: "Axios",
      model: "MOV Telefonía Celular",
      showInCatalog: true,
      cost: 25,
      price: 0,
      mainPicture:
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      msg: "Debes contar con 100 pesos por cada SIM que elijas.",
      SKU: "SIM-AX-MOV-1",
      includedWithProduct: false,
      qty: 4,
    },
    {
      pictures: [
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      ],
      _id: "636eb0127ea143001d126743",
      type: "SIM Card",
      brand: "Axios",
      model: "HBB Internet Hogar",
      showInCatalog: true,
      cost: 25,
      price: 0,
      mainPicture:
        "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      msg: "Debes contar con 100 pesos por cada SIM que elijas.",
      SKU: "SIM-AX-HBB-1",
      includedWithProduct: false,
      qty: 3,
    },
  ];
};

const useCartResume = () => {
  const [cartResume, setCartResume] = useState({
    productsQty: 0,
    productsTotal: 0,
    shippingCost: 0,
    cartTotal: 0,
    totalAfterPayment: 0,
  });

  const [cartProducts, setCartProducts] = useState([]);

  const onUpdateProductQty = (id, qty) => {
    const products = [...cartProducts];
    products.forEach((product) => {
      if (product._id == id) product.qty = qty;
    });
    setCartProducts(products);
  };

  useEffect(() => {
    const fetchedAddresses = fetchCartResume();
    setCartResume(fetchedAddresses);

    const cartProducts = fetchCartProducts();
    setCartProducts(cartProducts);
  }, []);
  return { cartResume, cartProducts, onUpdateProductQty };
};

export { useCartResume };
