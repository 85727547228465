import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
} from "reactstrap";

//redux

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

import logoLight from "@images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import { api } from "../../config";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";

const AddChild = (props) => {
  document.title = "New Password | Panda Móvil";
  const [isLoading, setIsLoading] = useState(false);
  const [successC, setSuccessC] = React.useState(false)
  const [errorC, setErrorC] = React.useState(false)
  const [msisdn, setMsisdn] = React.useState('')
  const [refW, setRefw] = React.useState('')
  const [name, setName] = React.useState('')
  const search = useLocation().search;
  const navigate = useNavigate();

  React.useEffect(() => {
    function setPid() {
      const ref = new URLSearchParams(search).get('ref');
      if (ref) {
        getInfoFromRelNumRef(ref)
        setRefw(ref)
      }else(
        navigate('/login')
      )
    }
    setPid();
  }, []);



  const getInfoFromRelNumRef= async (ref)=>{
    await axios.get(`${api.API_URL}/app/getInfoFromRelNum?ref=${ref}`).then((res)=>{
      setMsisdn(res.result.msisdn);
      setName(res.result.name);
    }).catch((error)=>{
      errorAlert("Error", '<p>Ocurrio un error al obtener la informacion</p>');
      setErrorC(true)
    })
  }

const accept =async ()=>{
  setIsLoading(true);
  try {
    let response = await axios.post(
      `${api.API_URL}/app/validateToken?ref=${refW}`
    );

    setIsLoading(false);
    if (response && response.status == 200) {
      setSuccessC(true);
      successAlert("Succes", response.result);
      return true;
    }

    // '/payment'
    return false;
  } catch (err) {
    setIsLoading(false);
    errorAlert("Error", "No fue posible realizar la relacion");
    return false;
  }
};

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Solicitud de administración</h5>
                    {name
                    ?
                    <h6 className="">{name} <br/>quiere administrar tu numero</h6>
                    :null
                    }
                  </div>
                  {!successC
                    ? <>
                      <Alert
                        className="border-0 alert-warning text-center mb-2 mx-2"
                        role="alert"
                      >
                      Al hacer clic en &quot;Sí, acepto&quot;, estás aceptando:
                      </Alert>
                        <p>No podrás actualizar tus datos, excepto la contraseña.</p>
                        <p>El administrador podrá ver y realizar acciones en tu cuenta.</p>
                        <p>Puedes realizar recargas, al igual que el administrador.</p>
                        <p>Podrás generar facturas, pero con los datos fiscales del administrador.</p>
                        <p>Para deshacer esta relación, contacta al administrador o llama al Callcenter.</p>
                      <div className="text-center mt-4">
                      {
                        !errorC
                        ?
                          <button className="btn btn-success w-100" type="submit" onClick={accept}>
                            Si, acepto
                          </button>
                        :null
                      }
                      </div>
                    </>
                    :
                    <Alert
                    className="border-0 alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    Ha aeptado la solicitud
                  </Alert>
              }
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Ir a iniciar sesion{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

AddChild.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AddChild);
