import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import axios from "axios";
import { api } from "../../../../config";
import { errorAlert, successAlert } from "../../../../helpers/alerts";

const DeleteSubModal = ({ isOpen, onToggle, onClose, device = {}, msisdn }) => {
  const onConfirmDelete = async () => {
    try {
      const response = await axios.post(`${api.API_URL}/app/unsubs`, {
        subid: device,
        msisdn,
      });

      successAlert("Eliminado", "Se ha cancelado la suscripción correctamente");
      onClose();
    } catch (error) {
      errorAlert("Error", "No se ha podido cancelar la suscripción");
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle;
        }}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <h5 className="text-primary">Confirmación</h5>
        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12">
              ¿Deseas cancelar la suscripción {device}?
            </Col>
            <Col xs={6} sm={5}>
              <Button
                color="primary"
                outline
                onClick={onClose}
                className="w-100 my-4"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6} sm={7}>
              <Button
                color="primary"
                onClick={onConfirmDelete}
                className="w-100 my-4"
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteSubModal;
