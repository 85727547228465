import React from "react";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import ModalPayments from "./ModalPayments";
import { useState } from "react";

const ProductCards = ({ e, pillTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card
      className="text-center cursor-pointer"
      style={{ width: "330px" }}
      onClick={handleOpen}
    >
      <CardHeader
        className=""
        style={{ background: "#5c666e", color: "white" }}
      >
        {e.name.split(pillTab.value)[1]}
      </CardHeader>
      <CardBody
        style={{
          background:
            "linear-gradient(240deg, rgba(108,108,108,1) 0%, rgba(227,227,227,1) 50%)",
        }}
      >
        <Row
          style={{
            fontSize: "2rem",
            color: "rgb(49, 62, 72)",
            fontStyle: "normal",
            fontWeight: "600",
          }}
        >
          <p>${e.amount}</p>
        </Row>
        <Row dangerouslySetInnerHTML={{ __html: e.description }}></Row>
      </CardBody>

      <ModalPayments isOpen={isOpen} onToggle={handleOpen} e={e} />
    </Card>
  );
};

export default ProductCards;
