import React from "react";

const PrintButton = () => {
  const print = () => {
    window.print();
  };
  return (
    <React.Fragment>
      <button
        className="btn btn-outline-primary d-flex justify-content-center"
        style={{ width: "100%" }}
        onClick={print}
      >
        <i className="la la-print m-1"></i>
        Imprimir
      </button>
    </React.Fragment>
  );
};

export default PrintButton;
