import React from "react";
import { Card, Col, Row } from "reactstrap";

import adv1 from "./images/adv-1.jpg";
import adv2 from "./images/adv-2.jpg";
import adv3 from "./images/adv-3.jpg";

const AdvertisingTab = () => {
  const advertisings = [
    {
      src: adv2,
      alt: "alt adv2",
    },
    {
      src: adv3,
      alt: "alt adv3",
    },
    {
      src: adv1,
      alt: "alt adv1",
    },
  ];
  return (
    <Row className="g-3">
      {advertisings.map((advertising, idx) => (
        <Col className="col-xxl col-3" key={idx}>
          <Card className="h-100">
            <img src={advertising.src} alt={advertising.alt} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default AdvertisingTab;
