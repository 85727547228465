import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [isBalances, setIsBalances] = useState(false);
  const [isSimsInventory, setSimsInventory] = useState(false);
  const [isProductsInventory, setProductsInventory] = useState(false);
  const [isMarketPlace, setMarketPlace] = useState(false);
  const [isPortability, setPortability] = useState(false);
  const [isActivation, setActivation] = useState(false);
  const [isPurchase, setIsPurchase] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isAdvertising, setIsAdvertising] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Sales") {
      setIsSales(false);
    }
    if (iscurrentState !== "Balances") {
      setIsBalances(false);
    }
    if (iscurrentState !== "SimsInventory") {
      setSimsInventory(false);
    }
    if (iscurrentState !== "ProductsInventory") {
      setProductsInventory(false);
    }
    if (iscurrentState !== "MarketPlace") {
      setMarketPlace(false);
    }
    if (iscurrentState !== "Portability") {
      setPortability(false);
    }
    if (iscurrentState !== "Activation") {
      setActivation(false);
    }
    if (iscurrentState !== "isPurchase") {
      setIsPurchase(false);
    }
    if (iscurrentState !== "Profile") {
      setIsProfile(false);
    }
    if (iscurrentState !== "Users") {
      setIsUsers(false);
    }
    if (iscurrentState !== "Reports") {
      setIsReports(false);
    }
    if (iscurrentState !== "Advertising") {
      setIsAdvertising(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isSales,
    isBalances,
    isSimsInventory,
    isProductsInventory,
    isMarketPlace,
    isPortability,
    isActivation,
    isPurchase,
    isProfile,
    isUsers,
    isReports,
    isAdvertising,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Inicio",
      icon: "las la-home",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    // {
    //   id: "ventas",
    //   label: "Ventas",
    //   icon: "las la-coins",
    //   link: "/ventas",
    //   stateVariables: isSales,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsSales(!isSales);
    //     setIscurrentState("Sales");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "saldos",
    //   label: "Saldos",
    //   icon: "las la-hand-holding-usd",
    //   link: "/saldos",
    //   stateVariables: isBalances,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsBalances(!isBalances);
    //     setIscurrentState("Balances");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "sims",
    //   label: "Inventario de SIMS",
    //   icon: "las la-sim-card",
    //   link: "/inventario-de-sims",
    //   stateVariables: isSimsInventory,
    //   click: function (e) {
    //     e.preventDefault();
    //     setSimsInventory(!isSimsInventory);
    //     setIscurrentState("SimsInventory");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "uf",
    //   label: "Consulta de perfil de UF",
    //   icon: "las la-sim-card",
    //   link: "/consulta-uf",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("ConsultaUF");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "pre-registro",
    //   label: "Pre-registro",
    //   icon: "las la-sim-card",
    //   link: "/pre-registro",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Pre-registro");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "recarga",
    //   label: "Recarga de saldo",
    //   icon: "las la-sim-card",
    //   link: "/recarga",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Recarga");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "productos",
    //   label: "Inventario de productos",
    //   icon: "las la-box",
    //   link: "/inventario-de-productos",
    //   stateVariables: isProductsInventory,
    //   click: function (e) {
    //     e.preventDefault();
    //     setProductsInventory(!isProductsInventory);
    //     setIscurrentState("ProductsInventory");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "marketplace",
    //   label: "Marketplace",
    //   icon: "las la-store-alt",
    //   link: "/marketplace",
    //   stateVariables: isMarketPlace,
    //   click: function (e) {
    //     e.preventDefault();
    //     setMarketPlace(!isMarketPlace);
    //     setIscurrentState("MarketPlace");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "portabilidad",
    //   label: "Portabilidad",
    //   icon: "las la-mobile-alt",
    //   link: "/portabilidad",
    //   stateVariables: isPortability,
    //   click: function (e) {
    //     e.preventDefault();
    //     setPortability(!isPortability);
    //     setIscurrentState("Portability");
    //     updateIconSidebar(e);
    //   },
    // },
    // {
    //   id: "activacion",
    //   label: "Activación",
    //   icon: "ri-sim-card-2-line",
    //   link: "/activacion",
    //   stateVariables: isActivation,
    //   click: function (e) {
    //     e.preventDefault();
    //     setActivation(!isActivation);
    //     setIscurrentState("Activation");
    //     updateIconSidebar(e);
    //   },
    // },
    {
      id: "recargas",
      label: "Recargas",
      icon: "las la-shopping-bag",
      link: "/recargas",
      stateVariables: isPurchase,
      click: function (e) {
        e.preventDefault();
        setIsPurchase(!isPurchase);
        setIscurrentState("Purschase");
        updateIconSidebar(e);
      },
    },
    {
      id: "Historial",
      label: "Historial de compras",
      icon: "las la-file-invoice",
      link: "/reportes",
      stateVariables: isReports,
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
    },
    {
      id: "perfil",
      label: "Perfil",
      icon: "las la-user",
      link: "/perfil",
      stateVariables: isProfile,
      click: function (e) {
        e.preventDefault();
        setIsProfile(!isProfile);
        setIscurrentState("Profile");
        updateIconSidebar(e);
      },
    },
    // {
    //   id: "usuarios",
    //   label: "Usuarios",
    //   icon: "las la-user-friends",
    //   link: "/usuarios",
    //   stateVariables: isUsers,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsUsers(!isUsers);
    //     setIscurrentState("Users");
    //     updateIconSidebar(e);
    //   },
    // },

    // {
    //   id: "publicidad-y-manuales",
    //   label: "Publicidad y manuales",
    //   icon: "las la-bullhorn",
    //   link: "/publicidad-y-manuales",
    //   stateVariables: isAdvertising,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsAdvertising(!isAdvertising);
    //     setIscurrentState("Advertising");
    //     updateIconSidebar(e);
    //   },
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
