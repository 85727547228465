import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Input, Col, Card, CardBody, Label } from "reactstrap";
import ExportDropdown from "@Components/UI/ExportDropdown";
import PrintButton from "@Components/UI/PrintButton";

const UserSims = () => {
  const { username } = useParams();
  const navigate = useNavigate();

  document.title = `SIMS de ${username} | Panda Móvil`;
  const headers = [
    { text: "No. Serie", value: "serialNumber" },
    { text: "Categoría", value: "category" },
    { text: "Marca", value: "brand" },
    { text: "Modelo", value: "model" },
    { text: "Disponible", value: "aviavility" },
    { text: "Historial", value: "history" },
  ];
  const fetchedUser = {
    id: 47,
    first_name: "Carole",
    last_name: "Stonebridge",
    user_name: "cstonebridge1a",
  };

  const productsList = [
    // {
    //   serialNumber: "",
    //   category: "",
    //   brand: "",
    //   model: "",
    //   aviavility: "",
    //   history: "",
    // },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Productos de {fetchedUser.user_name}</h1>
        <div className="d-flex">
          <div
            className="text-primary"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          >
            Regresar
          </div>
          <div className="mx-1">/</div>
          Ver productos
        </div>
        <Row>
          <Col xs={12}>
            <Row className="align-items-center justify-content-between my-2">
              <Col xs={12} sm={4}>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by SerialNumber"
                    aria-describedby="SerialNumberSearcher"
                    placeholder="Buscar por No. Serie"
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="SerialNumberSearcher"
                  >
                    <i className="la la-search"></i>
                  </button>
                </div>
              </Col>
              <Col xs={12} sm={3} md={2}>
                <button
                  className="btn btn-primary my-2"
                  type="button"
                  style={{ width: "100%" }}
                >
                  Restaurar filtros
                </button>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Row className="align-items-end">
                  <Col xs={12} sm={6} md={3}>
                    <Label className="form-label">Registros</Label>
                    <select className="form-select form-select-sm">
                      <option value>
                        Seleccione una cantidad de registros
                      </option>
                      <option defaultValue={1}> 10</option>
                      <option defaultValue={2}> 25</option>
                      <option defaultValue={3}> 50</option>
                      <option defaultValue={4}> 100</option>
                      <option defaultValue={5}> 250</option>
                      <option defaultValue={6}> 500</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Label className="form-label">Categoría</Label>
                    <select className="form-select form-select-sm">
                      <option value> Seleccione una categoría</option>
                      <option defaultValue={1}> Router</option>
                      <option defaultValue={1}> MiFi</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={4} md={2}>
                    <Label className="form-label">Marca</Label>
                    <select className="form-select form-select-sm">
                      <option value> Seleccione una marca</option>
                      <option defaultValue={1}> Quantum Connectivity</option>
                      <option defaultValue={1}> ShenZen</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={4} md={2}>
                    <Label className="form-label">Modelo</Label>
                    <select className="form-select form-select-sm">
                      <option value> Seleccione un modelo</option>
                      <option defaultValue={1}> Modelo 1</option>
                      <option defaultValue={1}> Modelo 2</option>
                    </select>
                  </Col>
                  <Col xs={12} sm={4} md={2}>
                    <Label className="form-label">Disponibilidad</Label>
                    <select className="form-select form-select-sm">
                      <option value> Seleccione disponibilidad</option>
                      <option defaultValue={1}> Modelo 1</option>
                      <option defaultValue={1}> Modelo 2</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center mt-4">
          <Col xs={12} sm={6} md={7} className="fs-6">
            Total productos: <b>{productsList.length}</b>
          </Col>
          <Col xs={12} sm={6} md={5} className="text-end">
            <Row className="my-2">
              <Col className="col-6">
                <ExportDropdown />
              </Col>
              <Col className="col-6">
                <PrintButton />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-responsive table-card">
                <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      {headers.map((header) => (
                        <th key={header.value} scope="col">
                          {header.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {productsList.length ? (
                      (productsList || []).map((item, index) => (
                        <tr key={index}>
                          {headers.map((header) => (
                            <td key={header.value + "-" + index}>
                              {item[header.value]}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%" className="text-center">
                          No hay registros existentes
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default UserSims;
