import React from "react";
import { Card, CardBody, Row } from "reactstrap";

export const RechargeCard = ({ recharge, onSelectRecharge }) => {
  return (
    <Card
      className="border card-border-primary rounded-4"
      style={{ cursor: "pointer" }}
      onClick={() => onSelectRecharge(recharge)}
    >
      <Row className="my-2">
        <h3 className="mb-0 text-primary text-center"> {recharge.name} </h3>
      </Row>
      <Row className="justify-content-center mx-auto">
        <div className="d-block px-2 pt-1">
          <div className="d-flex justify-content-between">
            <h6 className="lh-1 fw-normal" style={{ marginRight: "4px" }}>
              Vigencia:
            </h6>
            <h6 className="lh-1 fw-bold">{recharge.expiration}</h6>
          </div>
        </div>
      </Row>

      <Row className="justify-content-center mx-auto">
        <div className="d-block px-2">
          <div className="d-flex justify-content-between">
            <h6 className="lh-1 fw-normal" style={{ marginRight: "4px" }}>
              Gigas:
            </h6>
            <h6 className="lh-1 fw-bold">{recharge.capacity}</h6>
          </div>
        </div>
      </Row>

      <CardBody className="text-center">
        <h4 className="text-primary">
          ${parseFloat(recharge.price).toFixed(2)}
        </h4>
        <Row>
          <p className="card-text" style={{ fontSize: ".5rem" }}>
            {recharge.description}
          </p>
        </Row>
      </CardBody>
    </Card>
  );
};
