import React, { useState } from "react";
import { Row, Col, Button, Label, Card, CardBody } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { purchases } from "../../dummy/purchases";
import PurchaseCard from "./PurchaseCard";
import ProductListModal from "./ProductListModal";

const PurchasesTab = () => {
  const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(false);

  const onOpenPurchaseProducts = (purchaseId) => {
    setSelectedPurchaseId(purchaseId);
    // Todo fetch purchase by id
    setIsProductsModalOpen(true);
  };

  const [selectedPurchasesListCategory, setSelectedPurchasesListCategory] =
    useState("lastTen");

  const purchasesByCategory = {
    lastTen: { buttonText: "Mis últimas 10 compras", items: purchases },
    lastMonth: { buttonText: "Mis compras del mes", items: [] },
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} sm={4} md={3} lg={2}>
          <Row>
            <Col className="col-12 border-primary border-bottom mb-2">
              <div className="my-1">
                Filtros de búsqueda{" "}
                {!purchases.length || purchases.length > 1
                  ? `(${purchases.length} resultados)`
                  : `(${purchases.length} resultado)`}
              </div>
            </Col>
            {Object.entries(purchasesByCategory).map(
              ([categoryId, category]) => (
                <Col xs={6} sm={12} className="py-1" key={category.buttonText}>
                  <Button
                    color="primary"
                    className="py-2 my-1 d-flex justify-content-center align-items-center h-100"
                    style={{ width: "100%" }}
                    outline={categoryId != selectedPurchasesListCategory}
                    onClick={() => setSelectedPurchasesListCategory(categoryId)}
                  >
                    <div className="fs-6">{category.buttonText}</div>
                  </Button>
                </Col>
              )
            )}
            <Col className="col-12">
              <div className="my-3">
                <Label htmlFor="DateInput" className="form-label">
                  Buscar por fecha
                </Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    dateFormat: "d M, Y",
                  }}
                  defaultValue={new Date().toISOString()}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={8} md={8} lg={7} xl={5} className="mx-auto">
          {selectedPurchasesListCategory &&
          purchasesByCategory[selectedPurchasesListCategory] &&
          purchasesByCategory[selectedPurchasesListCategory].items.length ? (
            purchasesByCategory[selectedPurchasesListCategory].items.map(
              (purchase) => (
                <PurchaseCard
                  key={purchase._id}
                  purchase={purchase}
                  onViewProducts={onOpenPurchaseProducts}
                />
              )
            )
          ) : (
            <Card>
              <CardBody className="text-center">
                No se encontraron resultados con los filtros de seleccionados
              </CardBody>
            </Card>
          )}
          {selectedPurchaseId && (
            <ProductListModal
              isOpen={isProductsModalOpen}
              purchaseId={selectedPurchaseId}
              onClose={() => setIsProductsModalOpen(false)}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PurchasesTab;
