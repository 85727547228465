import React from "react";
import { Row, Col, Label } from "reactstrap";
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";
import { authProvider } from "../../../Components/Hooks/authProvider";
import { useState } from "react";
import { useEffect } from "react";
import DeleteSubModal from "./ApplicationsTab/DeleteSubModal";

const BillingTab = () => {
  const { user } = useLoggedIn();
  const { phones, getSubs, subs } = authProvider();

  const [phone, setPhone] = useState(user.msisdn);
  const [sub, setSub] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getSubs(phone);
  }, [phone]);

  const toggleOpenModal = (detail) => {
    setSub(detail.id);
    setModalVisible(true);
  };
  const toggleCloseModal = () => {
    setSub(null);
    setModalVisible(false);
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xs={10} sm={8}>
          <Label className="text-primary">Número</Label>
          <select
            className="form-select"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          >
            <option value={user.msisdn}>{user.msisdn}</option>
            {phones.map((e, index) => (
              <option key={'number-billing'+index} value={e.msisdn}>{e.msisdn}</option>
            ))}
          </select>
        </Col>
        <Col xs={10} sm={8} md={12} className="mt-4">
          {!!subs?.length && (
            <Row className="text-primary mb-4">Lista de suscripciones</Row>
          )}
          {subs?.length ? (
            (subs || []).map((e, index) => (
              <table key={'table-billing-'+index} className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    <th>ID</th>
                    <th className="text-center">Estado</th>
                    <th className="text-center">Precio</th>
                    <th className="text-center">Intervalo</th>
                    <th className="text-center">Comienza</th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{e.id}</td>
                    <td align="center">{e.status}</td>
                    <td align="center">
                      {parseFloat(e.plan.amount / 100).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td align="center">
                      {e.plan.interval_count + " " + e.plan.interval}
                    </td>
                    <td align="center">{e.current_period_start}</td>
                    <td align="center">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          toggleOpenModal(e);
                        }}
                      >
                        Cancelar Suscripción
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
          ) : (
            <Row className="justify-content-center">Sin suscripciones</Row>
          )}
        </Col>
      </Row>
      <DeleteSubModal
        isOpen={modalVisible}
        onClose={toggleCloseModal}
        device={sub}
        msisdn={phone}
      />
    </React.Fragment>
  );
};

export default BillingTab;
