import React from "react";
import { Card, Col, Row } from "reactstrap";

export const ProductCard = ({ product, onSelectProduct }) => {
  return (
    <Card
      className="border card-border-primary rounded-4 p-3"
      style={{ cursor: "pointer" }}
      onClick={() => onSelectProduct(product)}
    >
      <Row className="align-items-center">
        <Col sm={4}>
          <img src={product.mainPicture} style={{ width: "100%" }}></img>
        </Col>
        <Col sm={8}>
          <h5 className="mb-0 text-primary text-center">
            {" "}
            {product.brand} {product.model}{" "}
          </h5>
          <h6 className="text-center">
            {product.type},{product.brand}, {product.model}{" "}
          </h6>
        </Col>
        <Col className="col-12 text-center">
          <h4 className="fw-bold">
            {`$${parseFloat(product.price).toFixed(2)}`}
          </h4>
        </Col>
      </Row>
    </Card>
  );
};
