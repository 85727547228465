import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";

const AddAndroidDeviceModal = ({ isOpen, onToggle, onClose }) => {
  const onAddMacDevice = () => {};

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle;
        }}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <h5 className="text-primary">Agregar medio de venta Android</h5>
        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12 text-start">
              <Label
                htmlFor="macAddressInput"
                className="form-label text-primary"
              >
                MAC Address
              </Label>
              <Input
                type="text"
                className="form-control"
                id="macAddressInput"
                placeholder="Ej: a1:b2:c3:d4:e5:f6"
              />
            </Col>
            <Col xs={6} sm={5}>
              <Button
                color="primary"
                outline
                onClick={onClose}
                className="w-100 my-4"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6} sm={7}>
              <Button
                color="primary"
                onClick={onAddMacDevice}
                className="w-100 my-4"
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddAndroidDeviceModal;
