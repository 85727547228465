import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

const InformationTable = () => {
  const headers = [
    { text: "No. Serie", value: "serialNumber" },
    { text: "Categoría", value: "category" },
    { text: "Marca", value: "brand" },
    { text: "Modelo", value: "model" },
    { text: "Disponible", value: "aviavility" },
  ];
  const items = [];
  return (
    <React.Fragment>
      <Row className="align-items-center mt-4">
        <Col xs={12} sm={6} md={7} className="fs-4">
          Productos en esta transferencia
        </Col>
      </Row>
      <Col>
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {items.length ? (
                    (items || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td key={header.value + "-" + index}>
                            {item[header.value]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No hay registros existentes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InformationTable;
