import { useState } from "react";

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useState({
    msisdn: '',
    perPage: 10,
    page: 0
  });

  const handlePageSizeChange = (perPage) => {
    setSearchParams({
      ...searchParams,
      perPage
    });
  };
  
  const handlePageChange = (newPageNumber) => {
    setSearchParams({
      ...searchParams,
      page: newPageNumber
    });
  };
  
  const handleMSISDNSearchChange = (msisdn) => {
    setSearchParams({
      ...searchParams,
      msisdn,
      page: 1
    });
  };

  return { searchParams, handlePageChange, handleMSISDNSearchChange, handlePageSizeChange };
};