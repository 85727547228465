import React, { useEffect } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";
import { useState } from "react";
import { allRegimens } from "../../../helpers/utils";
import { authProvider } from "../../../Components/Hooks/authProvider";
import { errorAlert } from "../../../helpers/alerts";

const AccesosTab = () => {
  const { userInfo } = useLoggedIn();
  const { updateUser, changePassword } = authProvider();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [zip, setZip] = useState("");
  const [vat, setVat] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [l10n_mx_edi_fiscal_regime, setL10n_mx_edi_fiscal_regime] =
    useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  useEffect(() => {
    if (userInfo?.id) {
      setName(userInfo.name);
      setEmail(userInfo.email);
      setZip(userInfo.zip);
      setVat(userInfo.vat);
      setL10n_mx_edi_fiscal_regime(userInfo.l10n_mx_edi_fiscal_regime);
    }
  }, [userInfo]);

  const handleUpdate = async () => {
    if ([name, email].includes("")) {
      errorAlert("Error", "Ingresa todos los campos correspondientes");
      return;
    }

    const body = { name, email, zip, vat, l10n_mx_edi_fiscal_regime };
    await updateUser(body);
  };

  const handleUpdatePass = () => {
    if ([newPassword, currentPassword, confirmNewPassword].includes("")) {
      errorAlert("Error", "Ingresa todos los campos correspondientes");
      return;
    }

    const body = {
      user_password: newPassword,
      old_user_password: currentPassword,
      user_password_confirmation: confirmNewPassword,
    };

    changePassword(body);
  };

  return (
    <React.Fragment>
      <Form>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="firstnameInput" className="form-label">
                Nombre Completo
              </Label>
              <Input
                type="text"
                className="form-control"
                id="firstnameInput"
                placeholder="Ingrese su nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Col>
          {/* <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="lastnameInput" className="form-label">
                Apellido(s)
              </Label>
              <Input
                type="text"
                className="form-control"
                id="lastnameInput"
                placeholder="Ingrese su(s) apellido(s)"
                value="Mendoza"
              />
            </div>
          </Col> */}
          {/* <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="phonenumberInput" className="form-label">
                Número telefónico
              </Label>
              <Input
                type="text"
                className="form-control"
                id="phonenumberInput"
                placeholder="Ingrese su número telefónico"
                value="+(1) 987 6543"
              />
            </div>
            <div className="form-check form-switch form-switch-lg">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked
              />
              <Label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Activo
              </Label>
            </div>
          </Col> */}
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="emailInput" className="form-label">
                Email
              </Label>
              <Input
                type="email"
                className="form-control"
                id="emailInput"
                placeholder="Ingrese su email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="cp" className="form-label">
                Codigo Postal
              </Label>
              <Input
                type="number"
                className="form-control"
                id="cp"
                placeholder="CP"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="RFC" className="form-label">
                RFC
              </Label>
              <Input
                type="text"
                className="form-control"
                id="RFC"
                placeholder="RFC"
                value={vat ? vat : ''}
                onChange={(e) => setVat(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="Regimen" className="form-label">
                Regimen Fiscal
              </Label>
              <Input type="select"
                className="form-control"
                id="Regimen"
                value={l10n_mx_edi_fiscal_regime}
                onChange={(e) => setL10n_mx_edi_fiscal_regime(e.target.value)}
              >
                <option value="">Selecciona un regimen</option>
                {allRegimens.map((e, index) => (
                  <option value={e.value} key={'regimen-'+index}>{e.label}</option>
                ))}
              </Input>
            </div>
          </Col>

          {/* <Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="JoiningdatInput" className="form-label">
                Fecha de ingreso
              </Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                }}
                value={new Date().toISOString()}
              />
            </div>
          </Col> */}
          {/* <Row className="mt-4">
            <div className="fs-5">Condiciones comerciales</div>
            <Col xs={12} sm={8}>
              <CommissionsTable disabled />
            </Col>
          </Row> */}
          <Col lg={12} className="mt-4 mb-5">
            {/* TODO: Condicionar mostrar solo si existen cambios */}
            <div className="hstack gap-2 justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpdate}
              >
                Guardar cambios
              </button>
            </div>
          </Col>

          <Col lg={6}>
            <Label htmlFor="RFC" className="form-label">
              Contraseña Actual
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                type={passwordShow ? "text" : "password"}
                className="form-control"
                placeholder="Ingrese contraseña"
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </Col>

          <Col lg={6}>
            <Label htmlFor="RFC" className="form-label">
              Contraseña Nueva
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={passwordShow ? "text" : "password"}
                className="form-control"
                placeholder="Ingrese contraseña nueva"
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </Col>

          <Col lg={6}>
            <Label htmlFor="RFC" className="form-label">
              Confirmar Contraseña Nueva
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                type={passwordShow ? "text" : "password"}
                className="form-control"
                placeholder="Confirme contraseña nueva"
              />
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </Col>

          <Col lg={12} className="mt-4">
            {/* TODO: Condicionar mostrar solo si existen cambios */}
            <div className="hstack gap-2 justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleUpdatePass()}
              >
                Actualizar contraseña
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AccesosTab;
