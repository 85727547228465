import React from "react";
import { Col, Row } from "reactstrap";

const UserInfo = ({ user, phones, setPhone }) => {
  return (
    <Row className="d-flex align-items-center">
      {/* <Col xs={4} className="d-flex align-items-center justify-content-center">
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <i className="bx bx-dollar-circle fs-1 text-primary" />
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            Saldo
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            $1,000.00
          </Col>
        </Row>
      </Col> */}
      <Col xs={6} className="d-flex justify-content-center">
        <Row className="gy-2">
          <Col xs={12}>
            <Row>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <i className="bx bx-user fs-1 text-primary" />
              </Col>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                Usuario
              </Col>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center text-center"
              >
                {user.name}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col xs={6} className="d-flex justify-content-center">
        <Row>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <i className="bx bx-bookmark fs-1 text-primary" />
          </Col>

          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            Número
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <select onChange={(e) => setPhone(e.target.value)}>
              <option value={user.msisdn}>{user.msisdn}</option>
              {phones.map((e) => (
                <option key={e.msisdn} value={e.msisdn}>
                  {e.msisdn}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserInfo;
