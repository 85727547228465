import * as Yup from "yup";
import { useMutation } from '@tanstack/react-query';
import { postRecharge } from '../../helpers/fakebackend_helper';
import { useFormik } from "formik";
import { successAlert } from '../../helpers/alerts';

export const useRecharge = () => {
  const { mutate: recharge, isPending: loading, isSuccess, isError } = useMutation({
    mutationFn: (payload) => postRecharge(payload)
  });

  const rechargeForm = useFormik({
      initialValues: {
        msisdn: '',
        offeringId: ''
      },
      validationSchema: Yup.object({
        msisdn: Yup.string().required("Por favor ingrese número"),
        offeringId: Yup.string().required("Por favor ingrese oferta"),
      }),
      onSubmit: (rechargeInfo) => {
        handleRecharge(rechargeInfo);
      },
  });

  const handleRecharge = ({ msisdn, offeringId }) => {
    const payload = {
      msisdn,
      offerings: [offeringId]
    };
    //recharge(payload)
    successAlert('Recarga realizada', `Recarga procesada con éxito para ${msisdn}`);
    console.log(payload);
  };

  return { rechargeForm, loading, isSuccess, isError };
};
