import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { postLogin } from "../../helpers/fakebackend_helper";
import { loginSuccess, apiError } from "../../slices/auth/login/reducer";
import { TOKEN_KEY, setAuthorization } from "../../helpers/api_helper";
import axios from "axios";
import { api } from "../../config";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../../helpers/alerts";

export const useLogin = (navigation) => {
  const [userLogin, setUserLogin] = useState({ email: "", password: "" });
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginForm = useFormik({
    initialValues: {
      email: userLogin.email,
      password: userLogin.password,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor ingrese su email"),
      password: Yup.string().required("Por favor ingrese su contraseña"),
    }),
    onSubmit: (user) => {
      loginUser(user, navigation);
    },
  });

  const loginUser = async (user, navigation) => {
    setLoading(true);
    try {
      const data = await axios.post(`${api.API_URL}/app/login`, {
        msisdn: user.email,
        user_password: user.password,
      });

      if (data) {
        if (data.result) {
          sessionStorage.setItem(TOKEN_KEY, data.result);
          setAuthorization(data.result);
          setLoading(false);
        }

        if (data.status == 200) {
          localStorage.setItem("Profile", JSON.stringify(data.infoUsr));
          setLoading(false);
          navigate("/");
        } else {
          dispatch(apiError(data));
          setLoading(false);
          console.log(data);
        }
      }
    } catch (error) {
      dispatch(apiError(error));
      errorAlert("Ocurrio un error", "Revisa los datos ingresados");
      setLoading(false);
    }
  };

  return { loginForm, passwordShow, setPasswordShow, loading };
};
