import React, { useState } from "react";
import { Container, Row, Input, Col, Card, CardBody, Table, Spinner } from "reactstrap";
import { useQueryUF } from './useQueryUF';
import { formatDate } from '../../helpers/date_helper';
import { formatAmount } from '../../helpers/numbers_helper';
import Gauge from './Gauge';

const QueryUF = () => {
  const [number, setNumber] = useState('');
  const { getMSISDNStatus, statusDetails, gaugeData, loading } = useQueryUF();
  const searchMSISDN = (msisdn) => getMSISDNStatus(msisdn);

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Consulta de perfil de UF</h1>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h5>Consulta los datos del paquete del usuario</h5>
                <Row className="mt-3">
                  <Col xl={6}>
                    <div className="input-group">
                      <Input
                        disabled={loading}
                        type="tel"
                        className="form-control"
                        aria-label="Buscar por número telefónico"
                        aria-describedby="phoneSearcher"
                        placeholder="Número telefónico"
                        onInput={e => setNumber(e.target.value)}
                        onKeyDown={e => e.key === 'Enter' && searchMSISDN(number)}
                      />
                      <button
                        disabled={loading}
                        className="btn btn-primary"
                        type="submit"
                        id="phoneSearcher"
                        aria-label="Realizar búsqueda"
                        onClick={_ => searchMSISDN(number)}
                      >
                        <i className="la la-search"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <p className="text-muted">Si el numero pertenece a Pandamovil y está activo,
                    la consulta te devolverá los datos del paquete en curso.</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Card>
              <CardBody>
                { loading && <Spinner size={"md"} className="me-2" /> }
                { !loading && gaugeData &&
                  <div style={{position: 'relative'}}>
                    <Gauge data={gaugeData.dataR} labels={gaugeData.labelR} name={gaugeData.gaugeLabel}/>
                  </div>
                   }
              </CardBody>
            </Card>
          </Col>

          <Col lg={8}>
            <Card>
              <CardBody>
                { loading && <Spinner size={"md"} className="me-2" /> }
                {
                  !loading && statusDetails && <>
                  <h3 className="mb-5">Detalles de los servicios</h3>
                  {
                    statusDetails.map((status, index) =>
                      <ConsumedBreakdown key={'services'+index} status={status} includeTotal={false} />)
                  }
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                { loading && <Spinner size={"md"} className="me-2" /> }
                {
                  !loading && statusDetails && <>
                  <h3 className="mb-4">Desglose de consumo</h3>
                  { 
                    statusDetails.map((status, index) => <div key={'consumed'+index}>
                      <h5>{ status.title }</h5>
                      <p>Inicia el: { formatDate(status.effectiveDate) }</p>
                      <p>Expira el: { formatDate(status.expireDate) }</p>
                      <ConsumedBreakdown key={'status'+index} status={status} /></div>)
                  }</>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

function ConsumedBreakdown({ status, includeTotal = true }) {
  return (<>
    <Table responsive>
      <thead>
        <tr>
          <th></th>
          <th>Total(mb)</th>
          <th>Consumido(mb)</th>
          <th>Disponible(mb)</th>
        </tr>
      </thead>
      <tbody>
        <Section statusArr={status.data} total={status.totalData}/>
        { includeTotal && <SectionTotal total={status.totalData} /> }
        <Section statusArr={status.sms} total={status.totalSMS}/>
        { includeTotal && <SectionTotal total={status.totalSMS} /> }
        <Section statusArr={status.min} total={status.totalMin}/>
        { includeTotal && <SectionTotal total={status.totalMin} /> }
      </tbody>
    </Table>
  </>);
}

function Section({ statusArr }) {
  return (<>
    {
      statusArr.map((item, index) => (
        <tr key={item+index}>
          <th scope="row">{ item.description }</th>
          <td>{ formatAmount(Number(item.initialAmt)) }</td>
          <td>{ formatAmount(Number(item.usedAmt)) }</td>
          <td>{ formatAmount(Number(item.unusedAmt)) }</td>
        </tr>
      ))
    }
  </>);
}

function SectionTotal({ total }) {
  return (
    <tr>
      <th scope="row" className="fw-bold">Total</th>
      <td className="fw-bold">{ formatAmount(total.initialAmt) }</td>
      <td className="fw-bold">{ formatAmount(total.usedAmt) }</td>
      <td className="fw-bold">{ formatAmount(total.unusedAmt) }</td>
    </tr>
  );
}

export default QueryUF;
