const activationOffers = [
  {
    Carrier_ID: "204",
    Description: "Axios linea nueva",
    Group: "ACTIVACION",
    Monto: "90",
    offeringId: "1709901200",
    observacion:
      "*ACTIVA TU LINEA CON 5GB*. Aprovecha el beneficio de realizar portabilidad. Vigencia de 30 días. Permite compartir datos. Visita https://www.axiosmobile.mx",
    llamadas: "500",
    sms: "250",
    dias: "30",
    mb: "5000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "204",
    Description: "Axios linea nueva",
    Group: "ACTIVACION",
    Monto: "100",
    offeringId: "1779901052",
    observacion:
      "*ACTIVA TU LINEA CON 40GB(20 GB alta vel. + 20 GB vel. red.) Vigencia de 30 días. Visita https://www.axiosmobile.mx",
    llamadas: "1500",
    sms: "500",
    dias: "30",
    mb: "40000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "204",
    Description: "Axios linea nueva",
    Group: "ACTIVACION",
    Monto: "140",
    offeringId: "1709901201",
    observacion:
      "*ACTIVA TU LINEA CON 8GB*. Vigencia de 30 días. Permite compartir datos. Visita https://www.axiosmobile.mx",
    llamadas: "1500",
    sms: "500",
    dias: "30",
    mb: "8000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "204",
    Description: "Axios linea nueva",
    Group: "ACTIVACION",
    Monto: "150",
    offeringId: "1709901065",
    observacion:
      "*ACTIVA TU LINEA CON 40GB(20 GB alta vel. + 20 GB vel. red.) Vigencia de 30 días. Permite compartir datos. Visita https://www.axiosmobile.mx",
    llamadas: "1500",
    sms: "1500",
    dias: "30",
    mb: "40000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "204",
    Description: "Axios linea nueva",
    Group: "ACTIVACION",
    Monto: "250",
    offeringId: "1709901066",
    observacion:
      "100 GB (50GB alta vel + 50GB vel red). Permite compartir datos. Vigencia 30 días. Visita https://www.axiosmobile.mx",
    llamadas: "10000",
    sms: "10000",
    dias: "30",
    mb: "100000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "212",
    Description: "Axios linea nueva mas megas",
    Group: "ACTIVACION",
    Monto: "100",
    offeringId: "1779901051",
    observacion:
      "20 GB (10GB alta vel + 10GB vel red). Vigencia 15 días. Visita https://www.axiosmobile.mx",
    llamadas: "1000",
    sms: "250",
    dias: "15",
    mb: "20000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "215",
    Description: "Axios planes nueva linea",
    Group: "ACTIVACION",
    Monto: "350",
    offeringId: "1609901386",
    observacion:
      "PLAN 8GB 3 MESES. Vigencia de 3 meses. Navegación y redes sociales incluidas 8GB por mes. Visita https://www.axiosmobile.mx/",
    llamadas: "1500",
    sms: "500",
    dias: "90",
    mb: "8000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "215",
    Description: "Axios planes nueva linea",
    Group: "ACTIVACION",
    Monto: "450",
    offeringId: "1679901258",
    observacion:
      "PLAN 40GB 3 MESES. Vigencia de 3 meses. Navegación y redes sociales incluidas 40GB (20GB alta vel + 20 GB vel red) por mes. Visita https://www.axiosmobile.mx/",
    llamadas: "1500",
    sms: "500",
    dias: "90",
    mb: "40000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "215",
    Description: "Axios planes nueva linea",
    Group: "ACTIVACION",
    Monto: "500",
    offeringId: "1609901175",
    observacion:
      "PLAN 5GB 6 MESES - Vigencia de 6 meses, Navegación y redes sociales incluidas 5 GB por mes. Visita https://axiosmobile.mx/",
    llamadas: "1500",
    sms: "500",
    dias: "182",
    mb: "5000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "215",
    Description: "Axios planes nueva linea",
    Group: "ACTIVACION",
    Monto: "900",
    offeringId: "1609901320",
    observacion:
      "PLAN 5GB 12 MESES - Vigencia de 12 meses, Navegación y redes sociales incluidas 5 GB + 2GB (redes sociales) por mes. Visita https://axiosmobile.mx/",
    llamadas: "1500",
    sms: "500",
    dias: "365",
    mb: "5000",
    oferta_activa: "true",
    texto_oferta: "2GB regalo para redes sociales",
    check_amount: "true",
  },
  {
    Carrier_ID: "215",
    Description: "Axios planes nueva linea",
    Group: "ACTIVACION",
    Monto: "1000",
    offeringId: "1679901096",
    observacion:
      "PLAN 40GB 6 MESES - Vigencia de 6 meses, Navegación y redes sociales incluidas 40 GB (20GB alta vel + 20 GB vel reducida) por mes. Visita https://axiosmobile.mx/",
    llamadas: "1500",
    sms: "1000",
    dias: "182",
    mb: "40000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "215",
    Description: "Axios planes nueva linea",
    Group: "ACTIVACION",
    Monto: "1800",
    offeringId: "1679901097",
    observacion:
      "PLAN 40GB 12 MESES - Vigencia de 12 meses, Navegación y redes sociales incluidas 40 GB (20GB alta vel + 20 GB vel reducida) por mes. Visita https://axiosmobile.mx/",
    llamadas: "1500",
    sms: "1000",
    dias: "365",
    mb: "40000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "301",
    Description: "Internet Hogar Linea nueva",
    Group: "ACTIVACION",
    Monto: "89",
    offeringId: "1100501049",
    observacion:
      "Vigencia 7 días, incluye 20 GB, navegación 5 Mbps, aplican para consumo de Datos en Territorio Nacional dentro de la Cobertura para Productos de Internet en Casa (HBB). HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "7",
    mb: "20000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "301",
    Description: "Internet Hogar Linea nueva",
    Group: "ACTIVACION",
    Monto: "269",
    offeringId: "1100501051",
    observacion:
      "Vigencia 30 días, incluye 80 GB, navegación 5 Mbps, aplican para consumo de Datos en Territorio Nacional dentro de la Cobertura para Productos de Internet en Casa (HBB). HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "80000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "301",
    Description: "Internet Hogar Linea nueva",
    Group: "ACTIVACION",
    Monto: "329",
    offeringId: "1100501249",
    observacion:
      "Vigencia 30 días, incluye 120 GB, navegación 5 Mbps, aplican para consumo de Datos en Territorio Nacional dentro de la Cobertura para Productos de Internet en Casa (HBB). HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "120000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "301",
    Description: "Internet Hogar Linea nueva",
    Group: "ACTIVACION",
    Monto: "399",
    offeringId: "1100501250",
    observacion:
      "Vigencia 30 días, incluye 140 GB, navegación 5 Mbps, aplican para consumo de Datos en Territorio Nacional dentro de la Cobertura para Productos de Internet en Casa (HBB). HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "140000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "303",
    Description: "Internet Movil Linea nueva",
    Group: "ACTIVACION",
    Monto: "100",
    offeringId: "1409901048",
    observacion:
      "Vigencia 30 días, incluye 5 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "5000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "303",
    Description: "Internet Movil Linea nueva",
    Group: "ACTIVACION",
    Monto: "200",
    offeringId: "1409901095",
    observacion:
      "Vigencia 30 días, incluye 10 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "10000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "303",
    Description: "Internet Movil Linea nueva",
    Group: "ACTIVACION",
    Monto: "250",
    offeringId: "1409901096",
    observacion:
      "Vigencia 30 días, incluye 20 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "20000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
  {
    Carrier_ID: "303",
    Description: "Internet Movil Linea nueva",
    Group: "ACTIVACION",
    Monto: "450",
    offeringId: "1409901097",
    observacion:
      "Vigencia 30 días, incluye 50 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    llamadas: "0",
    sms: "0",
    dias: "30",
    mb: "50000",
    oferta_activa: "false",
    texto_oferta: "",
    check_amount: "true",
  },
];

export const lineaNuevaList = activationOffers.filter(({ Carrier_ID }) =>
  ["204", "212", "215"].includes(Carrier_ID)
);
export const hbbList = activationOffers.filter(({ Carrier_ID }) =>
  ["301"].includes(Carrier_ID)
);
export const mifiList = activationOffers.filter(({ Carrier_ID }) =>
  ["303"].includes(Carrier_ID)
);
