import React from "react";
import { Card, CardBody, Row, Col, Label, Input, Button } from "reactstrap";

const TitularUserForm = ({ children, onActivate, onReturn }) => {
  return (
    <React.Fragment>
      <Card className="border card-border-primary p-4">
        <Row className="justify-content-center fs-5 border-bottom border-primary border-opacity-50 mx-3 py-1">
          Datos del titular
        </Row>
        <CardBody>
          <Row>
            <Col className="col-12 py-1">
              <div>
                <Label htmlFor="nameInput" className="form-label">
                  Nombre
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="nameInput"
                  placeholder="Ingrese su nombre"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <div>
                <Label htmlFor="email" className="form-label">
                  Email
                </Label>
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Ingrese su email"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <div>
                <Label htmlFor="tel" className="form-label">
                  Teléfono de contacto
                </Label>
                <Input
                  type="tel"
                  className="form-control"
                  id="tel"
                  placeholder="Ingrese un teléfono de contacto"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <Label htmlFor="iccid" className="form-label">
                Iccid
              </Label>
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control"
                  id="iccid"
                  placeholder="Ingrese Iccid"
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  id="usernameSearcher"
                >
                  <i className="la la-search"></i>
                </button>
              </div>
            </Col>
            <Col className="col-12 py-1">
              <Label htmlFor="serviceNumber" className="form-label">
                Número de servicio
              </Label>
              <Input
                type="text"
                className="form-control"
                id="serviceNumber"
                disabled
              />
            </Col>
            {children}
            <Col className="col-12 py-1">
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="termsCheck"
                />
                <label
                  className="form-check-label"
                  htmlFor="termsCheck"
                  style={{ fontSize: ".8rem" }}
                >
                  Acepto términos de servicio
                </label>
              </div>
            </Col>
            <Col className="col-12 py-1">
              <Button
                color="primary"
                className="py-2 my-2 w-100"
                onClick={onActivate}
              >
                Activar
              </Button>
            </Col>
            <Col className="pt-4">
              <Button
                color="primary"
                outline
                className="w-auto py-3 d-flex align-items-center"
                onClick={onReturn}
              >
                <i className="la la-arrow-left fs-2"></i>
                Regresar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TitularUserForm;
