import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { VictoryPie, VictoryTooltip, VictoryContainer } from "victory";
import { Card, CardBody, CardHeader, Col, Progress, Row, Table } from "reactstrap";

const Statistics = ({ statusResponse }) => {
  const [metricsParsedData, setMetricsParsedData] = useState([]);
  const [totalRemaining, setTotalRemaining] = useState("");
  const [parsedDetails, setParsedDetails] = useState([]);

  function parseDetails(details, offers) {
    const detailsKeys = Object.keys(details);
    return detailsKeys.map((detailKey) => {
      const detail = details[detailKey];

      const startsAt = detail[0] && parseDate(detail[0].effectiveDate);
      const endsAt = detail[0] && parseDate(detail[0].expireDate);
      const title = detail[0] && offers[detail[0].offeringId];

      const detailsInfo = getNumericKeys(detail).map((key) => detail[key]);
      const smsLineParts = getSMSLineParts(detailsInfo);
      const minLineParts = getMinutesLineParts(detailsInfo);
      const lines = [
        {
          availableUnit: "sms",
          parts: smsLineParts,
          available: detail.total.sms.total - detail.total.sms.used,
          total: detail.total.sms.total,
          used: detail.total.sms.used,
        },
        {
          availableUnit: "min",
          parts: minLineParts,
          available: detail.total.min.total - detail.total.min.used,
          total: detail.total.min.total,
          used: detail.total.min.used,
        },
      ];

      return { title, startsAt, endsAt, lines };
    });
  }

  function getNumericKeys(obj) {
    return Object.keys(obj)
      .filter((key) => !isNaN(Number(key)))
      .map((num) => Number(num));
  }

  function createDetailLinePart(detail, color) {
    return (
      detail && {
        key: detail.name,
        title: detail.description,
        total: Number(detail.unusedAmt) + detail.usedAmt,
        unused: Number(detail.unusedAmt),
        used: detail.usedAmt,
        color,
      }
    );
  }

  function getSMSLineParts(detailInfo) {
    const nationalDetail = detailInfo.find(
      (detail) => detail.name == "FU_SMS_Altan-NR-LDI_NA"
    );
    const roamingDetail = detailInfo.find(
      (detail) => detail.name == "FU_SMS_Altan-NR-IR-LDI_NA"
    );

    const nationalPart = createDetailLinePart(nationalDetail, "#851823");
    const roamingPart = createDetailLinePart(roamingDetail, "#b62334");

    if (nationalPart && roamingPart) {
      return [nationalPart, roamingPart];
    } else {
      return null;
    }
  }

  function getMinutesLineParts(detailInfo) {
    const nationalDetail = detailInfo.find(
      (detail) => detail.name == "FU_Min_Altan-NR-LDI_NA"
    );
    const roamingDetail = detailInfo.find(
      (detail) => detail.name == "FU_Min_Altan-NR-IR-LDI_NA"
    );

    const nationalPart = createDetailLinePart(nationalDetail, "#851823");
    const roamingPart = createDetailLinePart(roamingDetail, "#b62334");

    if (nationalPart && roamingPart) {
      return [nationalPart, roamingPart];
    } else {
      return null;
    }
  }

  function parseDate(date) {
    if (date.length != 14) return null;

    const year = parseInt(date.substring(0, 4));
    const month = parseInt(date.substring(4, 6)) - 1;
    const day = parseInt(date.substring(6, 8));
    const hour = parseInt(date.substring(8, 10));
    const minute = parseInt(date.substring(10, 12));
    const second = parseInt(date.substring(12, 15));

    return new Date(year, month, day, hour, minute, second);
  }

  function getGraphData(metrics) {
    let dataR = {};
    let labelR = {};
    let totalR = [];
    let kR = {};
    var palletColor = [
      "#5c666e",
      "#ec3144",
      "#b62334",
      "#851824",
      "#3a4650",
      "#e7e6e6",
    ];
    let consumido = 0.0;
    for (let key in metrics.total) {
      let value = metrics.total[key];
      let targetKey = key.toLowerCase();
      if (
        targetKey.search("fu_rs_") === 0 ||
        targetKey.search("fu_data_") === 0 ||
        targetKey.search("freedata_") === 0 ||
        targetKey.search("fu_thrmbb") === 0 ||
        targetKey.search("fu_rs_") === 0
      ) {
        let randid = Math.floor(Math.random() * 999999);
        let pos =
          value.description == "Datos Velocidad Best Effort."
            ? 2
            : value.description == "Datos Velocidad Best Effort"
            ? 1
            : value.description == "Datos Roaming USA Canadá"
            ? 3
            : value.description == "Redes Sociales"
            ? 4
            : value.description == "Datos a velocidad 1 mbps"
            ? 5
            : value.description == "Datos a velocidad 512 kbps"
            ? 6
            : randid;
        consumido += value.totalAmt - value.unusedAmt;
        dataR[pos] = parseFloat(value.unusedAmt);
        totalR.push(parseFloat(value.unusedAmt).toFixed(2));
        labelR[pos] = value.description;
        kR[pos] = key;
      }
    }
    const total = parseFloat(
      totalR.reduce((a, b) => parseFloat(a) + parseFloat(b), 0.0)
    ).toFixed(2);
    dataR[0] = consumido;
    labelR[0] = "Consumido";
    let toReturn = [];
    let i = 0;
    for (let key in dataR) {
      if (key !== undefined) {
        toReturn.push({
          name:
            labelR[key] +
            "\n" +
            parseFloat(dataR[key] / 1000).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            }) +
            " Gb",
          amount: dataR[key],
          enabled: true,
          key: kR[key] == undefined ? "" : kR[key],
          fill: palletColor[i],
          pos: key,
        });
        i++;
      }
    }
    return { total, parsedMetrics: toReturn };
  }

  useEffect(() => {
    if (
      statusResponse &&
      "details" in statusResponse.result &&
      "total" in statusResponse.result
    ) {
      const { total, parsedMetrics } = getGraphData(statusResponse.result);
      setMetricsParsedData(parsedMetrics);
      setTotalRemaining(total);

      const parsedDetails = parseDetails(
        statusResponse.result.details,
        statusResponse.offername
      );
      setParsedDetails(parsedDetails);
    } else {
      setMetricsParsedData([]);
      setParsedDetails([]);
    }
  }, [statusResponse]);

  function formatDate(date) {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day} de ${month} del ${year}`;
    return formattedDate;
  }

  const toggleMetric = (index) => {
    const amountEnabled = metricsParsedData.filter(
      (data) => data.enabled
    ).length;
    const toggledData = metricsParsedData.map((data, i) => {
      if (index == i) {
        if (amountEnabled === 1) {
          const lastEnabledIndex = metricsParsedData.findIndex(
            (data) => data.enabled
          );
          return index !== lastEnabledIndex
            ? { ...data, enabled: !data.enabled }
            : { ...data };
        }
        return { ...data, enabled: !data.enabled };
      } else {
        return { ...data };
      }
    });
    setMetricsParsedData(toggledData);
  };

  const ProgressBarDetail = ({ text, total, value, color }) => {
    const [isPercentageShown, setIsPercentageShown] = useState(false);


    const percentageText = useMemo(() => {
      const percentage = value / total * 100;
      return (Number.isInteger(percentage) ? percentage.toString() : percentage.toFixed(2)) + '%';
    }, [value, total]);

    return (
      <Progress bar min={0} max={ total } value={ value } style={{ backgroundColor:color }}
      onMouseOver={() => setIsPercentageShown(true)} onMouseOut={() => setIsPercentageShown(false)}
      onClick={() => setIsPercentageShown(!isPercentageShown)}
      >
        { isPercentageShown ? percentageText : text }
      </Progress>
    );
  };

  return metricsParsedData.length > 0 ? (
    <>
      <Row className="justify-content-center">
          {metricsParsedData.map((metric, index) => (
            <Col key={'metric-'+index} className="col-auto">
              <button
                onClick={() => toggleMetric(index)}
                style={{
                  paddingRight: 21,
                  paddingTop: 5,
                  alignItems: "center",
                  background: "transparent",
                  border: "none",
                }}
              >
                <div className="d-flex justify-content-center">
                  <div
                    style={{
                      backgroundColor: metric.fill,
                      width: 15,
                      height: 15,
                      marginRight: 10,
                    }}
                  ></div>
                  <p
                    style={{
                      fontSize: 11,
                      textDecorationLine: metric.enabled
                        ? "none"
                        : "line-through",
                      textDecorationStyle: "solid",
                      background: "none",
                    }}
                  >
                    {metric.name}
                  </p>
                </div>
              </button>
            </Col>
          ))}
      </Row>

      <Row className="d-flex justify-content-center">
        <Col xs={12} md={8} className="d-flex flex-direction flex-column">
          <div>
            <svg viewBox="0 50 400 150" className="img-fluid">
              <VictoryPie standalone={false}
                startAngle={90}
                endAngle={-90}
                padAngle={0.8}
                data={metricsParsedData.filter((data) => data.enabled)}
                x="name"
                y="amount"
                innerRadius={70}
                labelRadius={100}
                labelComponent={
                  <VictoryTooltip
                    renderInPortal={false}
                    style={{ fill: "white", fontSize: 8 }}
                    flyoutStyle={{
                      fill: "rgba(52, 52, 52, 0.8)",
                    }}
                    animate={{ duration: 500, onLoad: { duration: 500 } }}
                  />
                }
                sortOrder="descending"
                sortKey="pos"
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onMouseLeave: () => {
                        return [
                          {
                            // Add an event to reset all the points to the original color
                            target: "labels",
                            eventKey: "all",
                            mutation: () => ({ active: false }),
                          },
                        ];
                      },
                      onMouseEnter: () => {
                        return [
                          {
                            target: "labels",
                            mutation: () => ({ active: true }),
                          },
                          {},
                        ];
                      },
                    },
                  },
                ]}
                style={{
                  labels: { fill: "black" },
                  data: { fill: ({ datum }) => datum.fill },
                }}
              />
            </svg>
          </div>
            <div>
              <h5 className="text-center" style={{ marginBottom: 0 }}>
                {totalRemaining} Mb
              </h5>
              <h2 className="text-center text-primary"> Total Disponible</h2>
            </div>
        </Col>
      </Row>


      <Row className="d-flex justify-content-center">
        <Col xl={6}>
          {parsedDetails.map((detail, index) => (
            <Card key={'detail-'+index}>
              <CardHeader className="pb-0">
                <h6 className="text-center">{detail.title}</h6>
                <Table borderless size="sm">
                  <tbody>
                    <tr>
                      <td>Inicia el:</td>
                      <td align="right">{formatDate(detail.startsAt)}</td>
                    </tr>
                    <tr>
                      <td>Termina el:</td>
                      <td align="right">{formatDate(detail.endsAt)}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              <CardBody>
                {detail.lines.map((line, index) => (
                    <Row key={'detail-line'+index} className="mb-3">
                      <div>
                        <Progress multi style={{height: 30}}>
                          <ProgressBarDetail text={'Consumido'} total={line.total} value={line.used} color={'#5c666e'}/>
                          {line.parts.map((part, index) => (
                            <ProgressBarDetail key={'part-'+index} text={part.title} total={line.total} value={part.unused} color={part.color}/>
                          ))}
                        </Progress>
                      </div>
                      <p className="mb-0">Disponible: {line.available} {line.availableUnit}</p>
                    </Row>
                ))}
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
    </>
  ) : (
    <p>Sin datos Disponibles</p>
  );
};

export default Statistics;
