import React from "react";
import { Card, Col, Row } from "reactstrap";

import iconAndroid from "./images/icon-android.png";
import iconWeb from "./images/icon-web.png";
import iconApple from "./images/icon-apple.png";

const ManualsTab = () => {
  const cards = [
    {
      title: "Web Cliente",
      src: iconWeb,
    },
    {
      title: "Apple Cliente",
      src: iconApple,
    },
    {
      title: "Android Cliente",
      src: iconAndroid,
    },
  ];
  return (
    <Row className="g-3">
      {cards.map((card, idx) => (
        <Col className="col-xxl" xs={12} sm={6} md={4} key={idx}>
          <Card className="h-100 border card-border-primary p-1">
            <Row className="h-100 d-flex align-items-center justify-content-center">
              <Col xs={6} sm={5} className="text-center">
                <img
                  style={{ width: "80%", maxWidth: "70px" }}
                  src={card.src}
                  alt="Card cap"
                />
                <div className="my-2">{card.title}</div>
              </Col>
              <Col xs={6} sm={7} className="text-center">
                <a className="mx-auto" style={{ cursor: "pointer" }}>
                  <i className="la la-file-pdf fs-3"></i>
                  Manual
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ManualsTab;
