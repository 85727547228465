export const purchases = [
  {
    "deliveryAddress": {
      "name": "Alejandro Mendoza",
      "phone": "4427489797",
      "street": "Prol constituyentes ote",
      "exteriorNumber": "475",
      "interiorNumber": "E13",
      "neighborhood": "fracc. zen life II",
      "cp": "76246",
      "city": "el marques",
      "state": "Queretaro",
      "references": "casa"
    },
    "status": "Entregado",
    "_id": "64cd5c67361198001de61433",
    "shippingFee": 120,
    "itemsTotal": 0,
    "itemsFee": 0,
    "purchasedProducts": [
      {
        "products": [
          {
            "_id": "644828225f2d7b1c9cf2dc5f",
            "imsi": "334140168824400",
            "iccid": "8952140062158620315F",
            "msisdn": "5650210428",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828215f2d7b1c9cf2c39b",
            "imsi": "334140168824401",
            "iccid": "8952140062158620323F",
            "msisdn": "5650210451",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828215f2d7b1c9cf2d009",
            "imsi": "334140168824402",
            "iccid": "8952140062158620331F",
            "msisdn": "5650210865",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828225f2d7b1c9cf2dc60",
            "imsi": "334140168824403",
            "iccid": "8952140062158620349F",
            "msisdn": "5650210929",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828225f2d7b1c9cf2d2f4",
            "imsi": "334140168824404",
            "iccid": "8952140062158620356F",
            "msisdn": "5650211152",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828215f2d7b1c9cf2ca0d",
            "imsi": "334140168824405",
            "iccid": "8952140062158620364F",
            "msisdn": "5650211385",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828215f2d7b1c9cf2ca0e",
            "imsi": "334140168824406",
            "iccid": "8952140062158620372F",
            "msisdn": "5650211498",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828215f2d7b1c9cf2ca0f",
            "imsi": "334140168824407",
            "iccid": "8952140062158620380F",
            "msisdn": "5650211525",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828215f2d7b1c9cf2c39c",
            "imsi": "334140168824408",
            "iccid": "8952140062158620398F",
            "msisdn": "5650211563",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          },
          {
            "_id": "644828225f2d7b1c9cf2d95e",
            "imsi": "334140168824409",
            "iccid": "8952140062158620406F",
            "msisdn": "5650211727",
            "product": "MOV",
            "owner": "alejandrom",
            "available": false
          }
        ],
        "product": "SIM Card MOV",
        "qty": 10,
        "price": 0
      }
    ],
    "purchaseTotal": 120,
    "client": "alejandrom",
    "requestedItems": 0,
    "requestedSims": 10,
    "balanceWhenPurchased": 1120,
    "createdAt": "2023-08-04T20:15:35.964Z",
    "updatedAt": "2023-08-16T21:46:46.683Z",
    "link": "https://www.paquetexpress.com.mx/",
    "notes": null,
    "trackingNumber": "611168852000"
  }
]