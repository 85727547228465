import React, { useEffect } from "react";
import { Row, Input, Col, FormText, FormGroup, Spinner, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useLogs } from './useLogs';
import { useSearchParams } from './useSearchParams';

const PreRegisterLogs = () => {
  const { searchParams, handlePageChange, handleMSISDNSearchChange, handlePageSizeChange } = useSearchParams();
  const { logs, pagination, loading: loadingLogs, loadLogs } = useLogs();

  const doSearch = () => loadLogs(searchParams.perPage, searchParams.page, searchParams.msisdn);

  useEffect(() => { doSearch() }, [searchParams]);

  return (<>
    { logs && <>
      <h3>Pre-registros</h3>
      <LogsTable logs={logs} />
      { pagination && 
      <Pages totalPages={pagination.totalPages} pageNumber={pagination.pageNumber} pageChange={handlePageChange}/>
      }
      <Row>
      <Col xs={12} sm={6} className="mt-2">
          <div className="input-group">
          <Input
              disabled={loadingLogs}
              type="tel"
              className="form-control"
              aria-label="Buscar por número telefónico"
              aria-describedby="msisdn-searcher"
              placeholder="MSISDN"
              onInput={e => handleMSISDNSearchChange(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && doSearch()}
          />
          <button
              disabled={loadingLogs}
              className="btn btn-primary"
              type="submit"
              id="msisdn-searcher"
              aria-label="Realizar búsqueda"
              onClick={_ => doSearch()}
          >
              { loadingLogs ? <Spinner size={"sm"} className="me-2" /> :
              <i className="la la-search"></i>
              }
          </button>
          </div>
      </Col>
      <Col xs={12} sm={6} className="mt-2">
          <FormGroup>
          <Input type="select"
              onInput={e => handlePageSizeChange(e.target.value)}
          >
              <option value={10}>10</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
          </Input>
          <FormText>Número de registros</FormText>
          </FormGroup>
      </Col>
      </Row>
      </>
    }
  </>);

  function LogsTable({ logs }) {
    return (
      <div style={{maxHeight: '728px', overflowY: 'scroll'}}>
        <Table>
          <thead>
            <tr>
              <th>Número migrado</th>
              <th>Oferta</th>
            </tr>
          </thead>
          <tbody>
            {
              logs.map((log, index) => (
                <tr key={log.msisdnPorted.toString() + index}>
                  <th scope="row">{ log.msisdnPorted }</th>
                  <td>{ log.offeringName }</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    );
  }

  function Pages({ totalPages, pageNumber, pageChange }) {
    if (totalPages < 1) {
      return null;
    }

    const list = Array.from({ length: totalPages }, (_, index) => index + 1);
    return (
      <Pagination className="d-flex justify-content-center">
        <PaginationItem disabled={pageNumber <= 0}><PaginationLink first onClick={() => pageChange(0)} /></PaginationItem>
        <PaginationItem disabled={pageNumber <= 0}><PaginationLink previous onClick={() => pageChange(pageNumber - 1)} /></PaginationItem>

        { list.map((number, index) => (
          <PaginationItem key={'page'+index} active={pageNumber == index}>
            <PaginationLink onClick={() => pageChange(number)} >{ number }</PaginationLink>
          </PaginationItem>
        )) }

        <PaginationItem disabled={pageNumber >= totalPages - 1}><PaginationLink next onClick={() => pageChange(pageNumber + 2)} /></PaginationItem>
        <PaginationItem disabled={pageNumber >= totalPages - 1}><PaginationLink last onClick={() => pageChange(totalPages)} /></PaginationItem>
      </Pagination>
    );
  }
};

export default PreRegisterLogs;