import React from "react";
import { Row, Input, Col, Card, CardBody, Button } from "reactstrap";

const ClientsTab = ({ onCreateUser }) => {
  const headers = [
    { text: "Cliente", value: "cliente" },
    { text: "Nombre", value: "nombre" },
    { text: "Esquema", value: "esquema" },
    { text: "Perfil", value: "perfil" },
    { text: "Referencia", value: "referencia" },
    { text: "Comisión", value: "comisión" },
    { text: "Activo", value: "activo" },
  ];

  const items = [];

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Row className="d-flex align-items-center justify-content-between my-2">
            <Col xs={12} sm={4}>
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control"
                  aria-label="Search by username"
                  aria-describedby="usernameSearcher"
                  placeholder="Buscar..."
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  id="usernameSearcher"
                >
                  <i className="la la-search"></i>
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="d-flex justify-content-between my-3">
        <div className="fs-6 my-2">
          Registros: <b>{items.length}</b>
        </div>
        <Button
          color="primary"
          size="sm"
          className="d-flex align-items-center"
          onClick={onCreateUser}
        >
          <i className="ri-user-add-fill fs-5 mx-2"></i>
          Crear usuario
        </Button>
      </div>
      <Col>
        <Card>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {items.length ? (
                    (items || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td key={header.value + "-" + index}>
                            {item[header.value]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No hay registros existentes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ClientsTab;
