import React, { useEffect } from "react";
import Select, { components } from "react-select";

import { Row, Input, Col, Label, Spinner } from "reactstrap";
import { authProvider } from "../../../../Components/Hooks/authProvider";
import { useLoggedIn } from "../../../../Components/Hooks/useLoggedIn";
import { useState } from "react";
import DataTable from "../DataTable";
import "./style.css";

const TimeAirTab = () => {
  const headers = [
    { text: "Nombre", value: "name" },
    { text: "Fecha", value: "date_order" },
    { text: "Hora", value: "date_order" },
    { text: "Total", value: "amount_total" },
  ];

  const { user } = useLoggedIn();
  const { getAllPhones, phones, getHistorial, historial, orderLines, loading } =
    authProvider();

  const [phone, setPhone] = useState(user.msisdn);
  const [date, setDate] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllPhones();
  }, []);

  useEffect(() => {
    getHistorial(phone);
  }, [phone]);

  useEffect(() => {
    console.log(date)
    if (date) {
      console.log(date)
      if(historial.length>0){
        const año = date.split("-")[0];
        const mes = date.split("-")[1];
        console.log(año,mes)

        const filter = historial.find((e) => e.año == año);
        const filterMonth = filter.meses.filter((e) => e.mes == mes);

        if (filterMonth) {
          const newData = [{ año: año, meses: filterMonth }];

          setData(newData);

        }
      }
    }
  }, [date]);

  const handleHeaderClick=(selected)=>{
    console.log(selected)
    const node = document.querySelector(`#${selected}`).parentElement
    .nextElementSibling;
  const classes = node.classList;
  if (classes.contains("collapsed")) {
    node.classList.remove("collapsed");
  } else {
    node.classList.add("collapsed");
  }
  }
  const OptionsMonth=()=>{
    const year = new Date().getFullYear();
    // let month = new Date().getMonth()+1;
    // month=month.length<2?"0"+month:month;
    let options = [];
    for (let index = year; index >= (parseInt(year)-6); index--){

      let interjson = {
          label: index,
          options: [
            { value:index+'-01', label:"Ene"},
            { value:index+'-02', label:"Feb"},
            { value:index+'-03', label:"Mar"},
            { value:index+'-04', label:"Abr"},
            { value:index+'-05', label:"May"},
            { value:index+'-06', label:"Jun"},
            { value:index+'-07', label:"Jul"},
            { value:index+'-08', label:"Ago"},
            { value:index+'-09', label:"Sep"},
            { value:index+'-10', label:"Oct"},
            { value:index+'-11', label:"Nov"},
            { value:index+'-12', label:"Dic"}
          ]

      }
      options.push(interjson);
    }
    const CustomGroupHeading = props => {
      return (
        <div
          className="group-heading-wrapper"
          onClick={() => handleHeaderClick(props.id)}
        >
          <components.GroupHeading {...props} />
        </div>
      );
    };




    return (
      <Select
      options={options}
      isMulti={false}
      blurInputOnSelect={false}
      closeMenuOnSelect={true}
      components={{ GroupHeading: CustomGroupHeading }}
      onChange={(e) => {
        setDate(e.value)
      }}
      inputValue={date}

    />
  )

  }
  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col xs={12}>
          <Row className="align-items-end justify-content-between my-2">
            <Col xs={12} sm={6} md={4} className="my-1">
              <Label className="form-label text-primary">Número</Label>
              <select
                className="form-select"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              >
                <option value={user.msisdn}>Tú {user.msisdn}</option>
                {phones.map((e) => (
                  <option value={e.msisdn}>{e.msisdn}</option>
                ))}
              </select>
            </Col>
            <Col xs={12} sm={6} md={4} className="my-1">
              <Label
                htmlFor="dateInput"
                className="form-label  text-primary"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                Fecha
              </Label>
              {/* <Input
                type="month"
                className="form-control"
                id="firstnameInput"
                placeholder="Ingrese su nombre"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              /> */}
              <OptionsMonth/>

            </Col>
            {/* <Col xs={12} sm={6} md={4} className="my-1">
              <button
                className="btn btn-primary d-flex align-items-center justify-content-center"
                type="button"
                style={{ width: "100%" }}
              >
                Buscar
              </button>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Col>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner />
          </div>
        ) : (
          <DataTable
            headers={headers}
            items={data.length ? data : historial}
            orderLines={orderLines}
            phone={phone}
          />
        )}
      </Col>

      {date && (
        <div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            width: "90%",
            bottom: "10px",
            zIndex: 10,
          }}
        >
          <button
            className="btn btn-primary d-flex align-items-center justify-content-center"
            type="button"
            onClick={() => {
              setDate("");
              setData(historial);
            }}
          >
            Reestablecer filtro
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default TimeAirTab;
