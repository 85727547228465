import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  logsResponse: null,
  loadingLogs: false,
  error: '', // for error message
  errorMsg: false, // for error
};

const preRegisterSlice = createSlice({
  name: "pre-register",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loadingOfferings = false;
      state.errorMsg = true;
    },
    setLogsResponse(state, action) {
      state.logsResponse = action.payload;
      state.loadingLogs = false;
      state.errorMsg = false;
    },
    setLoadingLogs(state, action) {
      state.loadingLogs = true;
    },
  },
});

export const { apiError, setLogsResponse, setLoadingLogs } = preRegisterSlice.actions;

export default preRegisterSlice.reducer;
