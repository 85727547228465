import React from "react";
import { Link } from "react-router-dom";
import { Col, Form, Input, Label, Row } from "reactstrap";
const PasswordTab = () => {
  return (
    <React.Fragment>
      <Row>
        <h2>Cambiar Contraseña</h2>
      </Row>
      <Row className="mb-3">
        <b>
          La última actualización de su contraseña fue el: 19/09/2023 12:31:59
        </b>
      </Row>
      <Form>
        <Row className="g-2">
          <Col lg={4}>
            <div>
              <Label htmlFor="oldpasswordInput" className="form-label">
                Antigua contraseña*
              </Label>
              <Input
                type="password"
                className="form-control"
                id="oldpasswordInput"
                placeholder="Ingrese antigua contraseña"
              />
            </div>
          </Col>

          <Col lg={4}>
            <div>
              <Label htmlFor="newpasswordInput" className="form-label">
                Nueva contraseña*
              </Label>
              <Input
                type="password"
                className="form-control"
                id="newpasswordInput"
                placeholder="Ingrese nueva contraseña"
              />
            </div>
          </Col>

          <Col lg={4}>
            <div>
              <Label htmlFor="confirmpasswordInput" className="form-label">
                Confirmar contraseña*
              </Label>
              <Input
                type="password"
                className="form-control"
                id="confirmpasswordInput"
                placeholder="Confirme la contraseña"
              />
            </div>
          </Col>

          <Col lg={12}>
            <div className="mb-3">
              <Link to="#" className="link-primary text-decoration-underline">
                ¿Ha olvidado su contraseña?
              </Link>
            </div>
          </Col>

          <Col lg={12}>
            <div className="text-end">
              <button type="button" className="btn btn-success">
                Actualizar contraseña
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default PasswordTab;
