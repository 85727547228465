import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';
import {
  reset_login_flag,
  apiError
} from "../../slices/auth/login/reducer";

export const useLoginPageData = () => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    user: state.Account.user,
    error: state.Login.error,
    loading: state.Login.loading,
    errorMsg: state.Login.errorMsg,
  }));

  const resetLoginFlag = () => async (dispatch) => {
    try {
      const response = dispatch(reset_login_flag());
      return response;
    } catch (error) {
      dispatch(apiError(error));
    }
  };

  const { user, error, loading, errorMsg } = useSelector(loginpageData);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 2000);
    }
  }, [errorMsg]);

  return { loading, error }
};
