import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "@images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import axios from "axios";
import { api } from "../../config";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const SetNewPassword = (props) => {
  document.title = "New Password | Panda Móvil";
  const [isLoading, setIsLoading] = useState(false);
  const [ref, setRef] = React.useState('');
  const [successC, setSuccessC] = React.useState(false)
  const search = useLocation().search;
  React.useEffect(() => {
    function setPid() {
      const ref = new URLSearchParams(search).get('ref');
      if (ref) {
        setRef(ref);
      }
    }
    setPid();
  }, []);

  const setNewPassword = async (payload) => {
    setIsLoading(true);
    const formvalues = {
      ref: ref,
      msisdn: payload.msisdn,
      user_password: payload.user_password,
      user_password_confirmation: payload.user_password_confirmation,
    };
    try {
      let response = await axios.post(
        `${api.API_URL}/app/validateToken`,
        formvalues
      );

      setIsLoading(false);
      if (response.status == 200) {
        successAlert("Succes", 'Se ha realizado el cambio con exito');
        setSuccessC(true);
        return true;
      }
      return false;
    } catch (err) {
      setIsLoading(false);
      errorAlert("Error", '<p>Ocurrio un error al recuperar la contraseña<br/><br/>Recuerde que dispone de 2 horas desde que recibe el SMS para realizar este cambio</p>');
      return false;
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      msisdn: "",
      user_password: "",
      user_password_confirmation: "",
    },
    validationSchema: Yup.object({
      msisdn: Yup.string().required("Por favor ingrese su numero"),
      user_password: Yup.string().required("Por favor ingrese su nueva contraseña"),
      user_password_confirmation: Yup.string().required("Por favor reingrese su nueva contraseña"),
    }),
    onSubmit: (values) => {
      setNewPassword(values);
    },
  });
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">¿Olvidaste tu contraseña?</h5>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>
                  {!successC
                    ? <>
                      <Alert
                        className="border-0 alert-warning text-center mb-2 mx-2"
                        role="alert"
                      >
                        LLena el siguiente formulario para recuperar tu contraseña!
                      </Alert>

                      <div className="p-2">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                          className="needs-validation"
                          action="#"
                        >
                          <div className="mb-4">
                            <Label className="form-label">Número</Label>
                            <Input
                              name="msisdn"
                              className="form-control"
                              placeholder="Ingresa numero"
                              type="numero"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.msisdn || ""}
                              invalid={validation.touched.msisdn && validation.errors.msisdn
                                ? true
                                : false
                              }
                            />
                            {validation.touched.msisdn && validation.errors.msisdn ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.msisdn}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Contraseña</Label>
                            <Input
                              name="user_password"
                              className="form-control"
                              placeholder="Ingresa nueva contraseña"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_password || ""}
                              invalid={
                                validation.touched.user_password &&
                                  validation.errors.user_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.user_password &&
                              validation.errors.user_password ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.user_password}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Confirmacion Contraseña</Label>
                            <Input
                              name="user_password_confirmation"
                              className="form-control"
                              placeholder="Re-ingresa nueva contraseña"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_password_confirmation || ""}
                              invalid={
                                validation.touched.user_password_confirmation &&
                                  validation.errors.user_password_confirmation
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.user_password_confirmation &&
                              validation.errors.user_password_confirmation ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.user_password_confirmation}</div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="text-center mt-4">
                            <button className="btn btn-success w-100" type="submit">
                              Restablecer Contraseña
                            </button>
                          </div>
                        </Form>
                      </div>
                    </>
                    :
                    <Alert
                    className="border-0 alert-warning text-center mb-2 mx-2"
                    role="alert"
                  >
                    El cambio se ha realizado con exito
                  </Alert>
              }


                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Espera, Ya recorde mi contraseña...{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

SetNewPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SetNewPassword);
