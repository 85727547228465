export const products = [
  {
    "pictures": [
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png"
    ],
    "_id": "636eb00c7ea143001d126742",
    "type": "SIM Card",
    "brand": "Axios",
    "model": "MOV Telefonía Celular",
    "showInCatalog": true,
    "cost": 25,
    "price": 0,
    "mainPicture": "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
    "msg": "Debes contar con 100 pesos por cada SIM que elijas.",
    "SKU": "SIM-AX-MOV-1",
    "includedWithProduct": false
  },
  {
    "pictures": [
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png"
    ],
    "_id": "636eb0127ea143001d126743",
    "type": "SIM Card",
    "brand": "Axios",
    "model": "HBB Internet Hogar",
    "showInCatalog": true,
    "cost": 25,
    "price": 0,
    "mainPicture": "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
    "msg": "Debes contar con 100 pesos por cada SIM que elijas.",
    "SKU": "SIM-AX-HBB-1",
    "includedWithProduct": false
  },
  {
    "pictures": [
      "https://res.cloudinary.com/ecoinforma/image/upload/v1667446048/esim/esim.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1667446048/esim/esim.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1667446048/esim/esim.png"
    ],
    "_id": "636ec67a7ea143001d12682b",
    "type": "SIM Card",
    "brand": "Axios",
    "model": "ESIM",
    "showInCatalog": true,
    "cost": 25,
    "price": 50,
    "mainPicture": "https://res.cloudinary.com/ecoinforma/image/upload/v1667446048/esim/esim.png",
    "msg": "Debes contar con 100 pesos por cada SIM que elijas.",
    "SKU": "SIM-AX-ESM-1",
    "includedWithProduct": false
  },
  {
    "pictures": [
      "https://http2.mlstatic.com/D_NQ_NP_772227-MLM44817889742_022021-O.webp"
    ],
    "_id": "638f936eadefc3001d3ffb22",
    "type": "MiFi",
    "brand": "ShenZhen",
    "model": "ZLT-30",
    "showInCatalog": true,
    "cost": 637,
    "price": 650,
    "usesSim": true,
    "simType": "MIFI",
    "mainPicture": "https://valortelecom.mx/ukokreew/2021/04/valor-telecom-modem-zlt-m30-01.png",
    "includedWithProduct": false,
    "SKU": "MF-SZ-ZLT30-1",
    "limitPerPurchase": 10
  },
  {
    "pictures": [
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
      "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png"
    ],
    "_id": "638f973cadefc3001d3ffc31",
    "type": "SIM Card",
    "brand": "Axios",
    "model": "MIFI",
    "showInCatalog": true,
    "cost": 25,
    "price": 0,
    "mainPicture": "https://res.cloudinary.com/ecoinforma/image/upload/v1664565697/Axios_flat_simcard.png",
    "msg": "Debes contar con 100 pesos por cada SIM que elijas.",
    "includedWithProduct": false,
    "SKU": "SIM-AX-MF-1"
  },
  {
    "pictures": [],
    "_id": "6500f42051a69544081fa1cc",
    "type": "Router",
    "brand": "ShenZhen",
    "model": "CPE A-11 Blanco",
    "showInCatalog": true,
    "cost": 650,
    "price": 650,
    "mainPicture": "https://images.cloud-services.mx/api/images/axios/market-products/modem-hbb-new-axios.png",
    "usesSim": true,
    "simType": "HBB",
    "SKU": "CPE A-11",
    "includedWithProduct": false,
    "limitPerPurchase": 5
  }
]