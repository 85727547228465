import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const headers = [
  { text: "Compañía", value: "company" },
  { text: "Tipo", value: "type" },
  { text: "Hora", value: "time" },
  { text: "Tel/Ref", value: "phone" },
  { text: "Folio", value: "id" },
  { text: "Monto", value: "amount" },
  { text: "Ticket", value: "ticket" },
];

const transactions = [
  {
    company: "Compañía A",
    type: "type A",
    time: "12:00",
    phone: "1234567890",
    id: "xyz",
    amount: 100,
    ticket: 2342342,
  },
];

const TransactionsTable = () => {
  const [isTopPageDropdown, setTopPageDropdown] = useState(false);
  const toggleDropdown = () => {
    setTopPageDropdown(!isTopPageDropdown);
  };
  return (
    <React.Fragment>
      <Col>
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Últimas transacciones
            </h4>
            <div className="flex-shrink-0">
              <Dropdown
                isOpen={isTopPageDropdown}
                toggle={toggleDropdown}
                className="card-header-dropdown"
              >
                <DropdownToggle
                  tag="a"
                  className="text-reset dropdown-btn"
                  role="button"
                >
                  <span className="text-muted fs-16">
                    <i className="mdi mdi-dots-vertical align-middle"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem>Today</DropdownItem>
                  <DropdownItem>Last Week</DropdownItem>
                  <DropdownItem>Last Month</DropdownItem>
                  <DropdownItem>Current Year</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(transactions || []).map((item, index) => (
                    <tr key={index}>
                      {headers.map((header) => (
                        <td key={header.value + "-" + index}>
                          {item[header.value]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default TransactionsTable;
