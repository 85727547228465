import React, { useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Label, Input } from "reactstrap";

import { loadAnimation } from "lottie-web";
import { defineElement } from "lord-icon-element";

import OperatorsRow from "./Components/OperatorsRow";
import TransactionsTable from "./Components/TransactionsTable";
import ProgressBar from "./Components/ProgressBar";
import { PhoneRecharges } from "./Components/PhoneRecharges";

defineElement(loadAnimation);

const Sales = () => {
  document.title = "Ventas | Panda Móvil";

  const [activeTab, setactiveTab] = useState(1);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedRecharge, setSelectedRecharge] = useState(null);

  function toggleTab(tab, value, e) {
    if (e) e.preventDefault();
    if (activeTab < tab) return;
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setProgressBarValue(value);
  }
  const onToggleStep = ({ tab, value }) => {
    toggleTab(tab, value);
  };

  function goToAmountTab(operator) {
    setSelectedOperator(operator);
    setactiveTab(2);
    setPassedSteps([1, 2]);
    setProgressBarValue(50);
  }

  const goToPhoneNumberForm = (recharge) => {
    setSelectedRecharge(recharge);
    setactiveTab(3);
    setPassedSteps([1, 2, 3]);
    setProgressBarValue(100);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Ventas</h1>
          <Row className="justify-content-center">
            <Col xl={8}>
              <Card>
                <CardBody>
                  <Form className="form-steps" autoComplete="off">
                    <ProgressBar
                      progressBarValue={progressBarValue}
                      activeTab={activeTab}
                      onToggleStep={onToggleStep}
                    />
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <Row className="">
                          <Col sm={4} className="text-center">
                            <h5 className="mb-1">Selecciona a un operador</h5>
                          </Col>
                        </Row>
                        <OperatorsRow onSelectOperator={goToAmountTab} />
                      </TabPane>

                      <TabPane tabId={2}>
                        <div className="d-flex justify-content-space-between align-items-center">
                          <Col xs={3} sm={4} className="text-center">
                            <img
                              src={selectedOperator}
                              width={"100%"}
                              style={{ cursor: "pointer", maxWidth: "120px" }}
                              onClick={() => toggleTab(1, 0)}
                            />
                          </Col>
                          <Col xs={6} sm={4} className="text-center">
                            <h5 className="mb-1">Selecciona un monto</h5>
                          </Col>
                        </div>
                      </TabPane>

                      <TabPane tabId={3}>
                        <div className="d-flex justify-content-space-between align-items-center">
                          <Col xs={3} sm={4} className="text-center">
                            <img
                              src={selectedOperator}
                              width={"100%"}
                              style={{ cursor: "pointer", maxWidth: "120px" }}
                            />
                          </Col>
                          <Col xs={6} sm={4} className="text-center ">
                            {selectedRecharge && (
                              <div className="text-primary fs-3 fw-bold">
                                ${parseFloat(selectedRecharge.price).toFixed(2)}
                              </div>
                            )}
                          </Col>
                          <Col xs={3} sm={4} className="text-end lh-1">
                            <small>Ingresa el número de celular</small>
                          </Col>
                        </div>
                        {selectedRecharge && (
                          <Alert
                            color="primary"
                            className="alert-primary-tooltip text-center pa-1 my-2"
                          >
                            {selectedRecharge.description}
                          </Alert>
                        )}
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {activeTab == 1 && <TransactionsTable />}
            {activeTab == 2 && (
              <div>
                <div className="d-flex justify-content-center">
                  <h4>Recargas Panda</h4>
                </div>
                <PhoneRecharges onSelectRecharge={goToPhoneNumberForm} />
                <div className="d-flex justify-content-center gap-3 m-3">
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-outline-primary previestab"
                    onClick={(e) => {
                      toggleTab(activeTab - 1, 0, e);
                    }}
                  >
                    Regresar
                  </button>
                </div>
              </div>
            )}
            {activeTab == 3 && (
              <Col xl={8}>
                <Row className="d-flex justify-content-center">
                  <Col lg={8}>
                    <div className="mb-3">
                      <Label htmlFor="phoneNumberInput" className="form-label">
                        Número telefónico
                      </Label>
                      <Input
                        type="tel"
                        className="form-control"
                        id="phoneNumberInput"
                        placeholder="Ingrese número telefónico"
                      />
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="mb-3">
                      <Label
                        htmlFor="confirmPhoneNumberInput"
                        className="form-label"
                      >
                        Confirme número telefónico
                      </Label>
                      <Input
                        type="tel"
                        className="form-control"
                        id="confirmPhoneNumberInput"
                        placeholder="Ingrese número telefónico"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center gap-3 m-3">
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-outline-primary previestab"
                    onClick={(e) => {
                      toggleTab(activeTab - 1, 50, e);
                    }}
                  >
                    Regresar
                  </button>
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none btn-success previestab"
                  >
                    Continuar
                  </button>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Sales;
