import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

const PhonePlanOffersCard = ({ offers = [] }) => {
  return (
    <React.Fragment>
      <Card className="border card-border-primary p-4">
        <Row className="justify-content-center fs-5 border-bottom border-primary border-opacity-50 mx-3 py-1">
          Ofertas disponibles
        </Row>
        <CardBody>
          <Row>
            {offers.map((offer) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                key={offer.offeringId}
                className="p-2"
              >
                <Card className="border card-border-primary h-100">
                  <CardBody className="row cursor-pointer">
                    <div className="col-12 text-center fz-1-1 text-primary bold mb-2 mt-1">
                      Panda {offer.Monto}
                    </div>
                    <div className="col-12 col-sm-6 fs-0-9">Vigencia:</div>
                    <div className="col-12 col-sm-6 fs-0-8 bold">
                      {offer.dias} días
                    </div>
                    <div className="col-12 col-sm-6 fs-0-9">Datos:</div>
                    <div className="col-12 col-sm-6 fs-0-9 bold">
                      {offer.mb / 1000} GB
                    </div>
                    <div className="col-12 text-center text-primary fs-1-2 bold mt-3 mb-3">
                      ${parseFloat(offer.Monto).toFixed(2)}
                    </div>
                    <div className="col-12 fs-0-7 mb-2">
                      {offer.observacion}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PhonePlanOffersCard;
