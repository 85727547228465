import Swal from 'sweetalert2';

export const successAlert = (title, html) => Swal.fire({
  title,
  html,
  icon: 'success',
});
export const errorAlert = (title, html) => Swal.fire({
  title,
  html,
  icon: 'error',
});
