import { useEffect, useMemo } from "react";
import { TOKEN_KEY } from "../../helpers/api_helper";
import { useQuery } from "@tanstack/react-query";
import { getIsAliveToken, getUserInfo } from "../../helpers/fakebackend_helper";
import { useNavigate } from "react-router-dom";

const useLoggedIn = () => {
  const userToken = sessionStorage.getItem(TOKEN_KEY);
  const auth = localStorage.getItem("Profile");
  const user = JSON.parse(auth);
  const navigate = useNavigate();

  const { isSuccess: isTokenAlive, isFetching: loading } = useQuery({
    queryKey: ["user-token"],
    queryFn: async () => await getIsAliveToken(),
    retry: false,
    enabled: !!userToken,
  });

  const { data: userInfo, isFetching: loadingUserInfo } = useQuery({
    queryKey: ['user-info'],
    queryFn: async () => await getUserInfo({ msisdn: user.msisdn }),
    retry: false,
    enabled: !!user,
    select: (res) => res.result,
    staleTime: Infinity,
  });

  const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    navigate("/login");
  };

  const isLoggedIn = useMemo(() => {
    return userToken && isTokenAlive;
  }, [userToken, isTokenAlive, loading]);

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      logout();
    }
  }, [userToken, isLoggedIn]);

  return { loading, userToken, isLoggedIn, logout, user, userInfo, loadingUserInfo };
};

export { useLoggedIn };
