import React, { useMemo } from "react";
import { Container, Row, Input, Col, Card, CardBody } from "reactstrap";
import { balances } from "./balancesDummy";
import ExportDropdown from "@Components/UI/ExportDropdown";
import PrintButton from "@Components/UI/PrintButton";

const Balances = () => {
  document.title = "Saldos | Panda Móvil";
  const headers = [
    // { text: "", value: "id" },
    { text: "Cliente", value: "user_name" },
    { text: "Nombre", value: "first_name" },
    { text: "Apellido(s)", value: "last_name" },
    { text: "Saldo Inicial", value: "saldoInicial" },
    { text: "Saldo Recibido", value: "saldoRecibido" },
    { text: "Venta", value: "venta" },
    { text: "Saldo Enviado", value: "saldoEnviado" },
    { text: "Residual", value: "residual" },
    { text: "Saldo Actual", value: "saldoActual" },
  ];

  const totals = useMemo(() => {
    return balances.reduce((totalObj, balance) => {
      headers.forEach(({ value }) => {
        const info = balance[value];
        if (typeof info == "number") {
          if (!totalObj[value]) totalObj[value] = 0;
          totalObj[value] += info;
        } else totalObj[value] = null;
      });
      return totalObj;
    }, {});
  }, [balances]);

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Saldos</h1>
        <Row>
          <Col xs={12}>
            <Row className="d-flex align-items-center justify-content-between my-2">
              <Col xs={12} sm={4}>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control"
                    aria-label="Search by username"
                    aria-describedby="usernameSearcher"
                    placeholder="Buscar..."
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="usernameSearcher"
                  >
                    <i className="la la-search"></i>
                  </button>
                </div>
              </Col>
              <Col xs={12} sm={4} className="text-end">
                <Row className="my-4">
                  <Col className="col-6">
                    <ExportDropdown />
                  </Col>
                  <Col className="col-6">
                    <PrintButton />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="text-end my-2">
            <button className="btn btn-primary py-2 px-6">Transferir</button>
          </Col>
        </Row>
        <Col>
          <Card>
            <CardBody>
              <div
                className="table-responsive table-card"
                style={{ maxHeight: "50vh" }}
              >
                <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      {headers.map((header) => (
                        <th key={header.value} scope="col">
                          {header.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(balances || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td
                            key={header.value + "-" + index}
                            className={
                              typeof item[header.value] == "number"
                                ? "text-end"
                                : "text-center"
                            }
                          >
                            {typeof item[header.value] == "number"
                              ? `$${parseFloat(item[header.value]).toFixed(2)}`
                              : item[header.value]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr className="table-light">
                      <td colSpan={3} className="text-end">
                        Totales:
                      </td>
                      {headers.slice(3).map(({ value }) => (
                        <td key={value} className="text-end">
                          {`$${parseFloat(totals[value]).toFixed(2)}`}
                        </td>
                      ))}
                    </tr>
                  </thead>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default Balances;
