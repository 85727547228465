import React from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { ProductsProvider } from "../../../Components/Hooks/ProductsProvider";
import { allRegimens, dataDropDownFormaPago } from "../../../helpers/utils";
import { useState } from "react";
import { useEffect } from "react";
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";
import { errorAlert } from "../../../helpers/alerts";

const ModalFactura = ({ isOpen, onToggle, invoice, phone }) => {
  const { AddFactura, isLoadingPPPaymentConfig } = ProductsProvider();
  const { userInfo } = useLoggedIn();

  const [dataDropDownUso, setDataDropDownUso] = useState([]);
  const [formaPago, setFormaPago] = useState("");
  const [uso, setUso] = useState("");

  useEffect(() => {
    const uses = allRegimens.find(
      (regimen) => regimen.value == userInfo.l10n_mx_edi_fiscal_regime
    );
    if (uses) {
      setDataDropDownUso(uses.use);
    }
  }, [userInfo]);

  const handleFacturar = async () => {
    if ([formaPago, uso].includes("")) {
      errorAlert("Error", "Selecciona todos los campos");
      return;
    }

    const formvalues = {
      mx_usage: uso,
      mx_method_id: formaPago,
      id: invoice,
      msisdn: phone,
    };
    const factura = await AddFactura(formvalues);
    if (factura) {
      onToggle();
    }
  };

  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            onToggle();
          }}
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalBody>
            <Row>
              <Col sm={12} md={6}>
                <Label className="text-primary">Forma de pago</Label>
                <select
                  className="form-select"
                  onChange={(e) => setFormaPago(e.target.value)}
                >
                  <option value="">Forma de pago</option>
                  {dataDropDownFormaPago.map((e) => (
                    <option value={e.value}>{e.label}</option>
                  ))}
                </select>
              </Col>
              <Col sm={12} md={6}>
                <Label className="text-primary">Uso</Label>
                <select
                  className="form-select"
                  onChange={(e) => setUso(e.target.value)}
                >
                  <option value="">Uso</option>
                  {dataDropDownUso.map((e) => (
                    <option value={e.value}>{e.label}</option>
                  ))}
                </select>
              </Col>

              <Col
                md={12}
                className="d-flex align-items-center justify-content-center mt-3"
              >
                <button className="btn btn-primary" onClick={handleFacturar}>
                  {isLoadingPPPaymentConfig ? (
                    <Spinner size="sm" />
                  ) : (
                    "Generar Factura"
                  )}
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ModalFactura;
