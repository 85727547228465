import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

export const ConfigTab = () => {
  return (
    <React.Fragment>
      <Row className="justify-content-center ">
        <Col xs={12} sm={9} md={8} lg={7}>
          <h4 className="text-primary">Tus comisiones</h4>
          <Row className="align-items-end">
            <Col xs={12} md={4} className="py-1">
              <Label
                htmlFor="comisionTaeInput"
                className="form-label text-primary"
              >
                Comisión TAE
              </Label>
              <Input
                type="text"
                className="form-control"
                id="comisionTaeInput"
                defaultValue="0"
              />
            </Col>
            <Col xs={12} md={4} className="py-1">
              <Label
                htmlFor="comisionServiciosInput"
                className="form-label text-primary"
              >
                Comisión servicios
              </Label>
              <Input
                type="text"
                className="form-control"
                id="comisionServiciosInput"
                defaultValue="0"
              />
            </Col>
            <Col xs={12} md={4} className="py-1">
              <Label
                htmlFor="comsionPinesInput"
                className="form-label text-primary"
              >
                Comisión pines
              </Label>
              <Input
                type="text"
                className="form-control"
                id="comsionPinesInput"
                defaultValue="0"
              />
            </Col>
          </Row>
          <h4 className="text-primary mt-4">Tu seguridad</h4>
          <Row>
            <Col className="col-12">
              <div className="form-check form-switch form-switch-md">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  defaultChecked
                />
                <Label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Mostrar saldo
                </Label>
              </div>
            </Col>
          </Row>
          <h4 className="text-primary mt-4">Tu seguridad</h4>
          <Row>
            <Col className="col-12">
              <div className="form-check form-switch form-switch-md">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  defaultChecked
                />
                <Label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  Mostrar datos fiscales
                </Label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Button color="primary" className="w-100 py-2 my-4">
                Guardar cambios
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
