import React from "react";
import "./Spinner.css";
const Spinner = ({ isLoading }) => {
	return isLoading ? (
    <div className="position-absolute page-loader z-3 pt-5 h-100 bg-light p-2 text-dark bg-opacity-50">

    <span className="spinner-border text-primary z-3  mt-5" role="status">
            <span className="visually-hidden pt-5 mt-5">Loading...</span>
        </span>
    </div>
	) : null;
};

export default Spinner;
