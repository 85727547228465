import { Col, Row, Spinner } from "reactstrap";

import UserInfo from "./UserInfo";
import { authProvider } from "../../Components/Hooks/authProvider";
import { useEffect } from "react";
import { useState } from "react";
import { useLoggedIn } from "../../Components/Hooks/useLoggedIn";
import Statistics from "./Statistics";

const Home = () => {
  const { getAllPhones, phones, getMetrics, isLoading } = authProvider();
  const { user } = useLoggedIn();
  const [phone, setPhone] = useState(user.msisdn);
  const [statusResponse, setstatusResponse] = useState(null);
  useEffect(() => {
    getAllPhones();
    refresh();
  }, [phone]);

  const refresh = async () => {
    const res = await getMetrics(await phone);
    setstatusResponse(res);
  };

  return (
    <div className="page-content px-5">
      <Row>
        {/* <Col
          xs={12}
          lg={7}
          xl={8}
          style={{ maxHeight: "90vh", overflowY: "scroll" }}
        >
          <div>
            <img className="img-fluid" src={marketingBanner1} />
          </div>
          <h3 className="mb-0 pt-4">Bancos</h3>
          <p>Aquí encontrarás las referencias bancarias</p>
        </Col> */}
        <Col xs={12} lg={12} xl={12} className="pt-4 pt-lg-0">
          <div className="mt-3" style={{ marginBottom: 40 }}>
            <UserInfo phones={phones} user={user} setPhone={setPhone} />
          </div>

          {isLoading ? (
            <Col
              xs={12}
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <Spinner style={{ width: 100, height: 100 }} />
            </Col>
          ) : (
            <Statistics statusResponse={statusResponse} />
          )}

          {/* <Row className="pb-5 pt-5">
            <Col xs={12} md={6}>
              <NoticesSlider />
            </Col>
            <Col xs={12} md={6}>
              <ShortcutsSection />
            </Col>
          </Row> */}
        </Col>
      </Row>
    </div>
  );
};

export default Home;
