import { useFormik } from "formik";
import { postPreRegister } from '../../helpers/fakebackend_helper';
import * as Yup from "yup";
import { errorAlert, successAlert } from '../../helpers/alerts';

export const usePreRegister = () => {
  const preRegisterForm = useFormik({
      initialValues: {
        msisdn: '',
        offeringId: '',
        msisdnPorted: '',
      },
      validationSchema: Yup.object({
        msisdn: Yup.string().required("Por favor ingrese número"),
        offeringId: Yup.string().required("Por favor ingrese oferta"),
        msisdnPorted: Yup.string().required("Por favor ingrese número entrante"),
      }),
      onSubmit: (preRegistration) => {
        preRegister(preRegistration);
      },
  });

  const preRegister = async ({ msisdn, offeringId, msisdnPorted }) => {
    try {
      await postPreRegister({ msisdn, offeringId, msisdnPorted });
      successAlert('Pre-registro exitoso');
      preRegisterForm.resetForm();
    } catch (e) {
      errorAlert('Error en pre-registro');
    }
  };

  return { preRegisterForm };
};
