import React, { useState } from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";

import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { CommissionsTable } from "../../../Components/UI/Commissions/CommissionsTable";

const CreateUserTab = () => {
  const [phone, setPhone] = useState(null);
  const [automaticPassword, setAutomaticPassword] = useState(true);
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <React.Fragment>
      <Row>
        <h4 className="mb-4">Accesos</h4>
      </Row>
      <Form>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="userInput" className="form-label">
                Usuario
              </Label>
              <Input
                type="text"
                className="form-control"
                id="userInput"
                placeholder="Ingrese un nombre de usuario"
                defaultValue="alejandrom"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="lastnameInput" className="form-label">
                Tipo de usuario
              </Label>
              <select className="form-select form-select">
                <option value>Seleccione un tipo de usuario</option>
                <option defaultValue={1}> Cliente</option>
                <option defaultValue={2}> Administrador</option>
                <option defaultValue={3}> Cajero</option>
              </select>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="firstnameInput" className="form-label">
                Nombre
              </Label>
              <Input
                type="text"
                className="form-control"
                id="firstnameInput"
                placeholder="Ingrese su nombre"
                defaultValue="Alejandro"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="lastnameInput" className="form-label">
                Apellido(s)
              </Label>
              <Input
                type="text"
                className="form-control"
                id="lastnameInput"
                placeholder="Ingrese su(s) apellido(s)"
                defaultValue="Mendoza"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <label htmlFor="cleave-phone" className="form-label">
                Número telefónico
              </label>
              <Cleave
                placeholder="(xxx)-xxx-xxxx"
                autoComplete="off"
                options={{
                  numericOnly: true,
                  creditCard: false,
                  blocks: [0, 3, 0, 3, 4],
                  delimiters: ["(", ")", "-"],
                  delimiterLazyShow: true,
                }}
                value={phone}
                onChange={onPhoneChange}
                className="form-control"
              />
            </div>
            <div className="form-check form-switch form-switch-lg">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                checked={automaticPassword}
                onClick={() => setAutomaticPassword(!automaticPassword)}
              />
              <Label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Contraseña automática
              </Label>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="emailInput" className="form-label">
                Email
              </Label>
              <Input
                type="email"
                className="form-control"
                id="emailInput"
                placeholder="Ingrese su email"
                defaultValue=""
              />
            </div>
          </Col>
          <Row className="mt-4">
            <div className="fs-5">Condiciones comerciales</div>
            <Col xs={12} sm={6}>
              <CommissionsTable />
            </Col>
          </Row>
          <Col lg={12} className="mt-4">
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-primary">
                Crear Usuario
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default CreateUserTab;
