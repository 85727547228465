import React from "react";
import axios from "axios";

import { Card, CardBody, Spinner } from "reactstrap";
import { api } from "../../../config";
import { useLoggedIn } from "../../../Components/Hooks/useLoggedIn";
import { useState } from "react";
import { ProductsProvider } from "../../../Components/Hooks/ProductsProvider";
import ModalFactura from "./ModalFactura";

const DataTable = ({
  headers = [],
  addCounterCol = false,
  counterColText = "No.",
  items = [],
  actionComponent = <div></div>,
  orderLines,
  phone,
}) => {
  const { user } = useLoggedIn();
  const { AddFactura, isLoadingPPPaymentConfig } = ProductsProvider();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const downloadInvoice = async (id, msisdn) => {
    setLoading(true);
    try {
      const response = await axios.post(`${api.API_URL}/app/downloadInvoice`, {
        id,
        msisdn,
      });

      var base64Data = response.result.datas;
      var binaryData = atob(base64Data);
      var arrayBuffer = new ArrayBuffer(binaryData.length);
      var uint8Array = new Uint8Array(arrayBuffer);

      for (var i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      var blob = new Blob([uint8Array], { type: response.result.mimetype });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = response.result.name;
      link.click();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const downloadInvoicePdf = async (detail) => {
    const formvalues = {
      id: detail.id,
      msisdn: phone,
    };
    await AddFactura(formvalues);
  };

  const toggleOpenModal = (detail) => {
    setInvoice(detail.id);
    if (detail.sale_invoice_status == "stamped") {
      downloadInvoicePdf(detail);
    } else {
      setModalVisible(true);
    }
  };

  const convertirFechaHora=(fechaHoraUTC) =>{
    const fechaUTC1 = new Date(fechaHoraUTC+' UTC');
    return fechaUTC1.toString();
}

  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                {addCounterCol && <th>{counterColText}</th>}
                {headers.map((header) => (
                  <th key={header.value} scope="col">
                    {header.text}
                  </th>
                ))}
                <th style={{ textAlign: "center" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {/* {items.length ? (
                (items || []).map((item, index) => (
                  <tr key={index}>
                    {addCounterCol && <td>{index + 1}</td>}
                    {headers.map((header) => (
                      <td
                        key={header.value + "-" + index}
                        style={{
                          width: header.value == "actions" ? "10%" : "auto",
                        }}
                      >
                        {header.value == "actions"
                          ? { actionComponent }
                          : item[header.value]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )} */}

              {items.length ? (
                items.map((e) =>
                  e.meses.map((e) =>
                    e.datos.map((e) => (
                      <tr>
                        <td>
                          {e.name} - {orderLines[e.id][0]}
                        </td>
                        <td>{e.date_order.split(" ")[0]}</td>
                        <td>{convertirFechaHora(e.date_order).split(" ")[4]}</td>
                        <td>${e.amount_total.toFixed(2)}</td>
                        <td
                          align="center"
                          className="d-flex gap-5 justify-content-center"
                        >
                          <button
                            style={{ width: "35%" }}
                            className="btn btn-secondary d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={() => downloadInvoice(e.id, phone)}
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner size={"sm"} className="me-2" />
                            ) : (
                              "Descargar Nota"
                            )}
                          </button>

                          <button
                            style={{ width: "35%" }}
                            className="btn btn-primary d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={() => toggleOpenModal(e)}
                            disabled={isLoadingPPPaymentConfig}
                          >
                            {isLoadingPPPaymentConfig ? (
                              <Spinner size={"sm"} className="me-2" />
                            ) : e.sale_invoice_status == "empty" ? (
                              "Solicitar Factura"
                            ) : e.sale_invoice_status == "failed" ? (
                              "Intentar de nuevo"
                            ) : e.sale_invoice_status == "stamped" ? (
                              "Ver/Compartir Factura"
                            ) : (
                              ""
                            )}
                          </button>
                        </td>
                      </tr>
                    ))
                  )
                )
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ModalFactura
          isOpen={modalVisible}
          invoice={invoice}
          onToggle={() => setModalVisible(false)}
          phone={phone}
        />
      </CardBody>
    </Card>
  );
};

export default DataTable;
