import React, { useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";

import ManagerTab from "./Components/ManagerTab";
import TransfersTab from "./Components/TransfersTab";

const ProductsInventory = () => {
  document.title = "Inventario de Productos | Panda Móvil";

  const tabsList = [
    { text: "Administración de productos", value: "1" },
    { text: "Transpasos realizados", value: "2" },
  ];

  const [pillTab, setPillTab] = useState(tabsList[0]);
  const togglePillTab = (tab) => {
    if (pillTab.value !== tab.value) {
      setPillTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Inventario de productos</h1>
          <Card>
            <CardBody>
              <Nav pills className="nav-justified mb-3">
                {tabsList.map((tab) => (
                  <NavItem key={tab.value}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: pillTab.value === tab.value,
                      })}
                      onClick={() => {
                        togglePillTab(tab);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={pillTab.value} className="text-muted">
                <TabPane tabId="1">
                  <ManagerTab />
                </TabPane>

                <TabPane tabId="2">
                  <TransfersTab />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductsInventory;
