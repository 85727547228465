import React from "react";

const QuantityPicker = ({ value, onUpdateValue, placeholder = "" }) => {
  const add = () => onUpdateValue(+value + 1);
  const substract = () => onUpdateValue(value ? +value - 1 : 0);
  const updateByInput = (e) => {
    const inputValue = +e.target.value;
    if (typeof inputValue == "number" && inputValue > 0) {
      onUpdateValue(+inputValue);
    } else onUpdateValue(0);
  };
  return (
    <React.Fragment>
      <div class="stepper stepper stepper-secondary">
        <a
          onClick={substract}
          class="cursor-pointer stepper-button stepper-down"
        >
          -
        </a>
        <input
          type="number"
          class="form-control"
          value={value}
          onInput={updateByInput}
          placeholder={placeholder}
        />
        <a onClick={add} class="cursor-pointer stepper-button stepper-up">
          +
        </a>
      </div>
    </React.Fragment>
  );
};

export default QuantityPicker;
