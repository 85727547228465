import React from "react";
import { Card, CardBody, Row, Col, Label, Input, Button } from "reactstrap";

const AddAddressForm = ({ onAddAddress, onReturn }) => {
  return (
    <React.Fragment>
      <Card className="border card-border-primary p-4">
        <CardBody>
          <Row className="align-items-end">
            <Col className="col-12 py-1">
              <div>
                <Label htmlFor="nameInput" className="form-label">
                  Nombre de quien recibe
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="nameInput"
                  placeholder="Nombre completo"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <div>
                <Label htmlFor="cityInput" className="form-label">
                  Ciudad
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="cityInput"
                  placeholder="Ciudad"
                />
              </div>
            </Col>
            <Col className="col-6 py-1">
              <Label className="form-label">Estado</Label>
              <select className="form-select">
                <option value disabled selected>
                  Seleccione un estado
                </option>
              </select>
            </Col>
            <Col className="col-6 py-1">
              <div>
                <Label htmlFor="zipCode" className="form-label">
                  Código postal
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="zipCode"
                  placeholder="Código postal"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <Label htmlFor="streetInput" className="form-label">
                Calle
              </Label>
              <Input
                type="text"
                className="form-control"
                id="streetInput"
                placeholder="Calle"
              />
            </Col>
            <Col className="col-6 py-1">
              <div>
                <Label htmlFor="externalNumber" className="form-label">
                  Número exterior
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="externalNumber"
                  placeholder="Número exterior"
                />
              </div>
            </Col>
            <Col className="col-6 py-1">
              <div>
                <Label htmlFor="internalNumber" className="form-label">
                  Número interior
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="internalNumber"
                  placeholder="Número interior"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <Label htmlFor="colony" className="form-label">
                Colonia
              </Label>
              <Input
                type="text"
                className="form-control"
                id="colony"
                placeholder="Colonia"
              />
            </Col>
            <Col className="col-12 py-1">
              <Label htmlFor="colony" className="form-label">
                Referencias (entre qué calles)
              </Label>
              <Input
                type="text"
                className="form-control"
                id="colony"
                placeholder="Referencias"
              />
            </Col>
            <Col className="col-6 py-1">
              <div>
                <Label htmlFor="phoneNumber" className="form-label">
                  Teléfono
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Teléfono"
                />
              </div>
            </Col>
            <Col className="col-6 py-1">
              <div>
                <Label htmlFor="secondaryPhoneNumber" className="form-label">
                  Teléfono secundario
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="secondaryPhoneNumber"
                  placeholder="Teléfono secundario"
                />
              </div>
            </Col>
            <Col className="col-12 py-1">
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="termsCheck"
                />
                <label
                  className="form-check-label"
                  htmlFor="termsCheck"
                  style={{ fontSize: ".8rem" }}
                >
                  Marcar como dirección principal
                </label>
              </div>
            </Col>
            <Col className="col-12 py-1">
              <Button
                color="primary"
                className="py-2 my-2 w-100"
                onClick={onAddAddress}
              >
                Agregar dirección
              </Button>
            </Col>
            <Col className="pt-4">
              <Button
                color="primary"
                outline
                className="w-auto py-3 d-flex align-items-center"
                onClick={onReturn}
              >
                <i className="la la-arrow-left fs-2"></i>
                Ir a direcciones
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AddAddressForm;
