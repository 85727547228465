import React from "react";
import { Nav, NavItem, NavLink, Progress } from "reactstrap";

import classnames from "classnames";

const ProgressBar = ({ progressbarvalue, activeTab, onToggleStep }) => {
  const sendSteps = (e, tab, value) => {
    e.preventDefault();
    onToggleStep({ tab, value });
  };

  return (
    <div className="progress-nav mb-4">
      <Progress value={progressbarvalue} style={{ height: "1px" }} />

      <Nav className="nav-pills progress-bar-tab custom-nav" role="tablist">
        <NavItem>
          <NavLink
            id="pills-gen-info-tab"
            className={classnames(
              {
                active: activeTab === 1,
                done: activeTab <= 4 && activeTab >= 0,
              },
              "rounded-pill"
            )}
            onClick={(e) => sendSteps(e, 1, 0)}
            tag="button"
          >
            1
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            id="pills-gen-info-tab"
            className={classnames(
              {
                active: activeTab === 2,
                done: activeTab <= 4 && activeTab > 1,
              },
              "rounded-pill"
            )}
            onClick={(e) => sendSteps(e, 2, 50)}
            tag="button"
          >
            2
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            id="pills-gen-info-tab"
            className={classnames(
              {
                active: activeTab === 3,
                done: activeTab <= 4 && activeTab > 2,
              },
              "rounded-pill"
            )}
            onClick={(e) => sendSteps(e, 3, 100)}
            tag="button"
          >
            3
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default ProgressBar;
