import React from "react";
import { Card, CardBody, Input } from "reactstrap";

const CheckImeiCard = ({ onOmit }) => {
  return (
    <React.Fragment>
      <Card className="h-100 border card-border-primary p-4">
        <h4>Consulta de IMEI</h4>
        <div>
          Marca <a href="tel:*#06#"> *#06# </a> en tu teléfono para consultar el
          IMEI de tu dispositivo.
        </div>
        <CardBody>
          <label htmlFor="cleave-phone" className="form-label"></label>
          <Input placeholder="Ingresa IMEI" className="fs-1-25rem" />
          <button className="btn btn-primary mt-4" style={{ width: "100%" }}>
            Consultar IMEI
          </button>
          <button
            className="btn btn-outline-primary mt-2"
            style={{ width: "100%" }}
            onClick={onOmit}
          >
            Omitir
          </button>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CheckImeiCard;
