import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

import logoLight from "@images/logo-light.png";
import { useLogin } from "./useLogin";
import { useLoginPageData } from "./useLoginPageData";

const Login = (props) => {
  const { loginForm, passwordShow, setPasswordShow, loading } = useLogin(
    props.router.navigate
  );
  const { error } = useLoginPageData();

  document.title = "Inicio de sesión | Panda Móvil";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Bienvenido</h5>
                      <p className="text-muted">
                        Inicie sesión para acceder a Panda Móvil.
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          loginForm.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Numero
                          </Label>
                          <Input
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Numero"
                            onChange={loginForm.handleChange}
                            onBlur={loginForm.handleBlur}
                            value={loginForm.values.email || ""}
                            invalid={
                              loginForm.touched.email && loginForm.errors.email
                            }
                            autoComplete="email"
                          />
                          {loginForm.touched.email && loginForm.errors.email ? (
                            <FormFeedback type="invalid">
                              {loginForm.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Contraseña
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={loginForm.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Ingrese contraseña"
                              onChange={loginForm.handleChange}
                              onBlur={loginForm.handleBlur}
                              invalid={
                                loginForm.touched.password &&
                                loginForm.errors.password
                              }
                              autoComplete="current-password"
                            />
                            {loginForm.touched.password &&
                            loginForm.errors.password ? (
                              <FormFeedback type="invalid">
                                {loginForm.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div> */}

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={error ? null : loading}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2"></Spinner>
                            ) : null}
                            Iniciar Sesión
                          </Button>
                        </div>
                      </Form>

                      <Row className="mt-3 d-flex">
                        <Col>
                          <a className="text-primary" href="/register">
                            Registrarme
                          </a>
                        </Col>

                        <Col className="text-end">
                          <a className="text-black" href="/forgot-password">
                            Olvidé mi contraseña
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
