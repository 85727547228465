import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PreRegisterLogs from './PreRegisterLogs';
import PreRegisterForm from './PreRegisterForm';

const PreRegister = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <h1>Pre-registro de usuario</h1>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <PreRegisterForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Card>
              <CardBody>
                <PreRegisterLogs />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PreRegister;
