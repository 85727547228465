import React, { useState } from "react";
import { Card, CardBody, Row, Col, Label, Input, Button } from "reactstrap";

import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";

const PortabilityProcessForm = ({ children, onContinue, onReturn }) => {
  const [phone, setPhone] = useState(null);
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <React.Fragment>
      <Card className="border card-border-primary p-4">
        <Row className="justify-content-center fs-5 border-bottom border-primary border-opacity-50 mx-3 py-1">
          Iniciar el proceso de portabilidad
        </Row>
        <CardBody>
          <Row>
            <Col className="col-12 py-1 text-start">
              <div>
                <Label htmlFor="nameInput" className="form-label">
                  Número a portar
                </Label>
                <Cleave
                  placeholder="(xxx)-xxx-xxxx"
                  options={{
                    numericOnly: true,
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ["(", ")", "-"],
                    delimiterLazyShow: true,
                  }}
                  value={phone}
                  onChange={onPhoneChange}
                  className="form-control fs-1-25"
                />
              </div>
            </Col>
            <Col className="col-12 py-1 text-start">
              <Label htmlFor="iccid" className="form-label">
                Iccid
              </Label>
              <div className="input-group">
                <Input
                  type="text"
                  className="form-control"
                  id="iccid"
                  placeholder="Ingrese Iccid"
                />
                <button
                  className="btn btn-primary"
                  type="button"
                  id="usernameSearcher"
                >
                  <i className="la la-search"></i>
                </button>
              </div>
            </Col>
            <Col className="col-12 py-1 text-start">
              <Label htmlFor="temporalNumber" className="form-label">
                Número temporal Panda
              </Label>
              <Input
                type="text"
                className="form-control"
                id="temporalNumber"
                placeholder="Tu número temporal Panda"
                disabled
              />
            </Col>
            <Col className="col-12 py-1 text-start">
              <div>
                <Label htmlFor="nipNumber" className="form-label">
                  NIP
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="nipNumber"
                  defaultValue={1234}
                />
              </div>
            </Col>

            {children}
            <Col className="col-12 py-1 text-start">
              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="termsCheck"
                />
                <label
                  className="form-check-label"
                  htmlFor="termsCheck"
                  style={{ fontSize: ".8rem" }}
                >
                  Portar con paquete
                </label>
              </div>
            </Col>
            <Col className="col-12 py-1 text-start">
              <Button
                color="primary"
                className="py-2 my-2 w-100"
                onClick={onContinue}
              >
                Continuar
              </Button>
            </Col>
            <Col className="pt-4">
              <Button
                color="primary"
                outline
                className="w-auto py-3 d-flex align-items-center"
                onClick={onReturn}
              >
                <i className="la la-arrow-left fs-2"></i>
                Regresar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default PortabilityProcessForm;
