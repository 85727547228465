import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";

const MyBalanceModal = ({ isOpen, onToggle, onClose }) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle;
        }}
        centered
        className="mx-auto"
        style={{ maxWidth: "300px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <h4>Resumen de saldo</h4>
        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12 text-center">
              <span class="text-muted">Saldo inicial</span>
              <h2 class="text-muted fz-3 mt-0 mb-2 fw-500">$1,000.00</h2>
            </Col>
            <Col className="col-6 text-center border-primary border-end border-top border-bottom">
              <div class="flex-center flex-column flex-1 br p-1">
                <h2 class="text-success mt-0 mb-2 text-center fz-2 fw-500">
                  +$0.00
                </h2>
                <span class="text-muted text-center fz-0-8 fw-500">
                  Compras del día
                </span>
              </div>
            </Col>
            <Col className="col-6 text-center border-primary border-start border-top border-bottom">
              <div class="flex-center flex-column flex-1 p-1">
                <h2 class="text-danger mt-0 mb-2 text-center fz-2 fw-500">
                  -$0.00
                </h2>
                <span class="text-muted text-center fz-0-8 fw-500">
                  Ventas del día
                </span>
              </div>
            </Col>
            <Col className="col-12 text-center pt-3">
              <span class="text-muted fz-0-8">Saldo Actual</span>
              <h2 class="text-primary mt-0 mb-0 fz-3 fw-500">$1,000.00</h2>
            </Col>
            <Col xs={12} sm={8}>
              <Button color="primary" onClick={onClose} className="w-100 my-4">
                Aceptar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default MyBalanceModal;
