import React from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import axios from "axios";
import { api } from "../../../../config";
import { successAlert } from "../../../../helpers/alerts";

const DeleteDeviceModal = ({ isOpen, onToggle, onClose, device = {} }) => {
  const onConfirmDelete = async () => {
    try {
      const response = await axios.post(`${api.API_URL}/app/delFromList`, {
        msisdntodel: device.msisdn,
      });

      successAlert("Eliminado", "Se ha quitado de tu lista correctamente");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle;
        }}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-center">
          <h5 className="text-primary">Confirmación</h5>
        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12">
              ¿Deseas eliminar el número {device.msisdn}?
            </Col>
            <Col xs={6} sm={5}>
              <Button
                color="primary"
                outline
                onClick={onClose}
                className="w-100 my-4"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6} sm={7}>
              <Button
                color="primary"
                onClick={onConfirmDelete}
                className="w-100 my-4"
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteDeviceModal;
