import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import QuantityPicker from "@Components/UI/QuantityPicker";

const ProductDetailsModal = ({
  isOpen,
  product,
  onToggle,
  onAddToCart,
  onClose,
}) => {
  const [qty, setQty] = useState("");
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          onToggle;
        }}
        centered
        className="mx-auto"
        style={{ maxWidth: "350px" }}
      >
        <ModalHeader className="modal-title d-flex justify-content-end">
          <i
            className="la la-times-circle cursor-pointer text-primary fs-1-5"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody className="text-center p-2 mx-2">
          <Row className="justify-content-center">
            <Col className="col-12 text-center">
              <img src={product.mainPicture} style={{ width: "80%" }} />
            </Col>
            <Col className="col-12 pt-3">
              <h5 className="text-primary">
                {product.brand} {product.model}
              </h5>
              <h6 className="">
                {product.type},{product.brand}, {product.model}{" "}
              </h6>
              <h4 className="fw-bold text-primary">
                {`$${parseFloat(product.price).toFixed(2)}`}
              </h4>
              <div className="fs-0-7 text-primary">{product.msg}</div>
            </Col>
            <Col className="col-12">
              <Row className="py-3 align-items-center">
                <Col xs={12} sm={6}>
                  <QuantityPicker
                    value={qty}
                    onUpdateValue={setQty}
                    placeholder="Ingrese un valor"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Button
                    color="primary"
                    className="w-100 h-100 btn-sm"
                    disabled={!qty}
                    onClick={onAddToCart}
                  >
                    Agregar al carrito
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ProductDetailsModal;
