import React from "react";

import { Card, CardBody } from "reactstrap";

const DataTableCard = ({
  headers = [],
  addCounterCol = false,
  counterColText = "No.",
  items = [],
  actionComponent = <div></div>,
}) => {
  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                {addCounterCol && <th>{counterColText}</th>}
                {headers.map((header) => (
                  <th key={header.value} scope="col">
                    {header.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                (items || []).map((item, index) => (
                  <tr key={index}>
                    {addCounterCol && <td>{index + 1}</td>}
                    {headers.map((header) => (
                      <td
                        key={header.value + "-" + index}
                        style={{
                          width: header.value == "actions" ? "10%" : "auto",
                        }}
                      >
                        {header.value == "actions"
                          ? { actionComponent }
                          : item[header.value]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default DataTableCard;
