import React from "react";
import { Col, Row } from "reactstrap";

import { RechargeCard } from "./RechargeCard";

export const PhoneRecharges = ({ onSelectRecharge }) => {
  const recharges = [
    {
      name: "Panda x",
      expiration: "7 días",
      capacity: "20gb",
      price: 89,
      description:
        "Vigencia 7 días, incluye 20 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    },
    {
      name: "Panda y",
      expiration: "30 días",
      capacity: "80gb",
      price: 269,
      description:
        "Vigencia 30 días, incluye 80 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    },
    {
      name: "Panda z",
      expiration: "30 días",
      capacity: "100gb",
      price: 329,
      description:
        "Vigencia 30 días, incluye 100 GB de datos libres para navegación y Redes Sociales, aplican para consumo de Datos en Territorio Nacional HOTSPOT permitido.",
    },
  ];
  return (
    <Row className="justify-content-center my-4">
      <Col sm={10}>
        <Row className="justify-content-center">
          {recharges.map((recharge, idx) => (
            <Col xs={12} sm={6} md={4} lg={3} key={idx}>
              <RechargeCard
                recharge={recharge}
                onSelectRecharge={onSelectRecharge}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
