import React from "react";
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const ExportDropdown = ({
  className = "btn-outline-primary",
  onExcel,
  onCsv,
}) => {
  return (
    <React.Fragment>
      <ButtonGroup className="w-100">
        <UncontrolledDropdown className="w-100">
          <DropdownToggle
            tag="button"
            className={`d-flex justify-content-center btn ${className}`}
            style={{ width: "100%" }}
          >
            <i className="la la-file-alt m-1"></i>
            Exportar
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={onExcel}>Excel</DropdownItem>
            <DropdownItem onClick={onCsv}>Csv</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default ExportDropdown;
