import { createSelector } from 'reselect';
import { getPreRegisterLogs } from '../../helpers/fakebackend_helper';
import { apiError, setLogsResponse, setLoadingLogs } from './reducer';
import { useDispatch, useSelector } from "react-redux";
import { useOfferings } from './useOfferings';

export const useLogs = () => {
  const dispatch = useDispatch();
  const { offerings } = useOfferings();

  const loadLogs = async (perpage, page, msisdn) => {
    try {
        dispatch(setLoadingLogs());
        const response = await getPreRegisterLogs(perpage, page, msisdn);

        if (response && response.status === 200) {
            dispatch(setLogsResponse(response));
        } else {
            dispatch(apiError(response));
        }
    } catch (error) {
        dispatch(apiError(response));
    }
  };

  const logsSelector = createSelector(s => s, (state) => {
    const preRegister = state.PreRegister;
    
    return {
      logs: preRegister.logsResponse ? parseLogs(preRegister.logsResponse, offerings) : null,
      pagination: preRegister.logsResponse ? parsePagination(preRegister.logsResponse) : null,
      loading: preRegister.loadingLogs
    };
  });

  const { logs, loading, pagination } = useSelector(logsSelector);

  return { logs, loading, pagination, loadLogs };
};

function parseLogs (logsResponse, offerings) {
  if (logsResponse && logsResponse.result && logsResponse.result.data && offerings) {
		return logsResponse.result.data
			.filter(log => log.body)
			.map(log => parsePreRegisterFromLog(log.body))
      .filter(Boolean)
      .map(log => {
        log.offeringName = offerings[log.offeringId];
        return log;
      });
  }
  return [];
}

function parsePagination (logsResponse) {
  if (logsResponse && logsResponse.result) {
    return {
      pageNumber: logsResponse.result.current_page - 1,
      pageSize: Number(logsResponse.result.per_page), 
      totalPages: logsResponse.result.last_page
    };
  }
  return { pageNumber: 0, pageSize: 10, totalPages: 1 };
}

function parsePreRegisterFromLog(log) {
	try {
		return JSON.parse(log);
	} catch (e) {
		return null;
	}
}
