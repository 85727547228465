import React from "react";
import { Col, Button } from "reactstrap";

const SearchLocationButton = () => {
  return (
    <React.Fragment>
      <Col className="col-12 py-1">
        <Button color="primary" outline className="py-2 my-2 w-100">
          Escribe las coordenadas o búscalas con el pin
          <i className="la la-map-marker mx-2"></i>
        </Button>
      </Col>
    </React.Fragment>
  );
};

export default SearchLocationButton;
