import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import Paypal from "../../assets/images/payments/paypal.png";
import MercadoPago from "../../assets/images/payments/MercadoPago.svg";
import { useLoggedIn } from "../../Components/Hooks/useLoggedIn";
import { authProvider } from "../../Components/Hooks/authProvider";
import { useState } from "react";
import { ProductsProvider } from "../../Components/Hooks/ProductsProvider";
import { errorAlert, successAlert } from "../../helpers/alerts";
import { useEffect } from "react";
import SpinnerCool from "../../Components/Common/Spinner/Spinner";

const ModalPayments = ({ isOpen, onToggle, e }) => {
  const { user } = useLoggedIn();
  const { phones, getSubs, subs } = authProvider();
  const {
    InitPaymentPP,
    ValPaymentPP,
    isLoadingPPPaymentConfig,
    InitPaymentMP,
    paymentStripe,
  } = ProductsProvider();

  const [phone, setPhone] = useState(user.msisdn);
  const [paypalUrl, setPaypalUrl] = useState("");

  const [fullName, setFullname] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [recurrentPayment, setRecurrentPayment] = useState(false);
  const [showPays, setShowPays] = useState(false);
  const [stripePaymentProcess, setStripePaymentProcess] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    getSubs(phone);
  }, [phone]);

  const initPP = async () => {
    try {
      await InitPaymentPP({
        msisdn: phone,
        offerid: e.offer_id,
      }).then((data) => {
        const { links } = data.paypal;
        const approvalUrl = links.find(
          (data) => data.rel == "approval_url"
        ).href;
        if (approvalUrl) {
          window.location.href = approvalUrl;
        }
        setPaypalUrl(approvalUrl ? approvalUrl : "");
      });
    } catch (err) {
      errorAlert("Paypal salio mal", err.message);
    }
  };

  const initMP = async () => {
    const preferenceURL = await InitPaymentMP({
      msisdn: phone,
      offerid: e.offer_id,
    }).then((data) => {
      return data.status === 200 ? data.response.mp.url : false;
    });
    try {
      window.location.href = preferenceURL;
    } catch (err) {
      errorAlert("Mercadopago salio mal", err.message);
    }
  };

  const handleStripePayment = async (i) => {
    i.preventDefault();
    const formvalues = {
      fullName: fullName,
      cardNumber: cardNumber,
      month: month,
      year: "20" + year,
      cvv: cvv,
      offerid: e.offer_id,
      msisdn: phone,
      recurrentPayment: subs.length === 0 ? recurrentPayment : false,
    };
    setStripePaymentProcess(true);
    await paymentStripe(formvalues)
    .then((res) => {
      if (res) {
        successAlert("Pago exitoso", "Se ha realizado el pago con exito");
        setCardNumber('');
        setMonth("");
        setYear("");
        setCvv("");
        setFullname("");
      }
      setStripePaymentProcess(false)
    });
  };

  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            onToggle();
          }}
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalHeader className="modal-title d-flex justify-content-center">
            {/* <h5 className="text-primary">Medio de pago</h5> */}
          </ModalHeader>
          <ModalBody className="text-center p-2 mx-2">
            <Row className="justify-content-center d-flex align-items-center">
            <SpinnerCool  isLoading={stripePaymentProcess}/>
              <Col md={6}>
                <Row className="justify-content-center">
                  <Card className="text-center" style={{ width: "330px" }}>
                    <CardHeader
                      className=""
                      style={{ background: "#5c666e", color: "white" }}
                    >
                      {e.name}
                    </CardHeader>
                    <CardBody
                      style={{
                        background:
                          "linear-gradient(240deg, rgba(108,108,108,1) 0%, rgba(227,227,227,1) 50%)",
                      }}
                    >
                      <Row
                        style={{
                          fontSize: "2rem",
                          color: "rgb(49, 62, 72)",
                          fontStyle: "normal",
                          fontWeight: "600",
                        }}
                      >
                        <p>${e.amount}</p>
                      </Row>
                      <Row
                        dangerouslySetInnerHTML={{ __html: e.description }}
                      ></Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Label className="text-primary">Número</Label>
                    <select
                      className="form-select"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    >
                      <option value={user.msisdn}>Tú {user.msisdn}</option>
                      {phones.map((e) => (
                        <option value={e.msisdn}>{e.msisdn}</option>
                      ))}
                    </select>
                  </Col>

                  <Col className="col-12 mt-4 text-primary">
                    Elige pagar con:
                  </Col>

                  {!showPays && (
                    <div
                      className="mt-4 cursor-pointer"
                      style={{
                        background: "#ffc439",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                      }}
                      onClick={initPP}
                    >
                      {isLoadingPPPaymentConfig ? (
                        <Spinner color="white" />
                      ) : (
                        <img src={Paypal} height="100%" />
                      )}
                    </div>
                  )}

                  {!showPays && (
                    <div
                      className="mt-4 cursor-pointer"
                      style={{
                        background: "#a8e5ff",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                        padding: "7px",
                      }}
                      onClick={initMP}
                    >
                      {isLoadingPPPaymentConfig ? (
                        <Spinner color="white" />
                      ) : (
                        <img src={MercadoPago} height="100%" />
                      )}
                    </div>
                  )}

                  {/* <div
                    className="mt-4"
                    style={{
                      background: "#ffffff",
                      width: "70%",
                      borderRadius: "4px",
                      border: "none",
                      height: "40px",
                      padding: "7px",
                    }}
                  >
                    <img src={Conekta} height="100%" />
                  </div> */}

                  <div
                    className="mt-4 cursor-pointer"
                    style={{
                      background: "#645aff",
                      width: "70%",
                      borderRadius: "4px",
                      border: "none",
                      height: "40px",
                      padding: "7px",
                      color: "white",
                    }}
                    onClick={() => setShowPays(!showPays)}
                  >
                    <p>Pago con tarjeta</p>
                  </div>

                  {showPays && (
                    <form onSubmit={handleStripePayment}>
                      <Row className="justify-content-center mt-4">
                        <Col xs={10}>
                          <Label className="text-primary">Nombre</Label>
                          <Input
                            placeholder="Nombre en la tarjeta"
                            required
                            value={fullName}
                            onChange={(e) => setFullname(e.target.value)}
                          />
                        </Col>

                        <Col xs={10} className="mt-2">
                          <Label className="text-primary">Tarjeta</Label>
                          <Input
                            placeholder="**** **** **** ***"
                            type="number"
                            required
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                          />
                        </Col>

                        <Col xs={10} className="mt-2">
                          <Label className="text-primary">Vigencia</Label>
                          <div className="d-flex align-items-center gap-3">
                            <Input
                              placeholder="01"
                              type="number"
                              required
                              value={month}
                              onChange={(e) => setMonth(e.target.value)}
                            />
                            /
                            <Input
                              placeholder="20"
                              type="number"
                              required
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            />
                          </div>
                        </Col>

                        <Col xs={10} className="mt-2">
                          <Label className="text-primary">CVV</Label>
                          <Input
                            placeholder="***"
                            type="number"
                            required
                            value={cvv}
                            onChange={(e) => setCvv(e.target.value)}
                          />
                        </Col>

                        <Col xs={10} className="mt-2 gap-2 d-flex">
                          <Input
                            placeholder="***"
                            type="checkbox"
                            checked={recurrentPayment}
                            onChange={(e) =>
                              setRecurrentPayment(!recurrentPayment)
                            }
                          />
                          <Label style={{ fontSize: "12px" }}>
                            Aceptar cobro recurrente
                          </Label>
                          <i
                            class="fas fa-question-circle text-primary"
                            id="infoIcon"
                          ></i>
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen}
                            target="infoIcon"
                            toggle={toggleTooltip}
                          >
                            El cobro recurrente consiste en que al cabo del
                            periodo del paquete, Se hará un cobro por la misma
                            cantidad, dandote los beneficios del paquete
                            adquirido.
                            <br />
                            Este cobro se hará al termino de cada periodo hasta
                            que usted decida cancelarlo.
                          </Tooltip>
                        </Col>

                        <Col xs={10}>
                          <Button
                            disabled={stripePaymentProcess}
                            color="primary"
                            type="submit"
                            className="w-100 my-4"
                          >
                            Pagar
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  )}
                </Row>
              </Col>
              <Col xs={6} sm={5}>
                <Button
                disabled={stripePaymentProcess}
                  color="primary"
                  outline
                  onClick={onToggle}
                  className="w-100 my-4"
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ModalPayments;
