import React from "react";
import { Row, Col, Button } from "reactstrap";
import { Card } from "reactstrap";
const PurchaseCard = ({ purchase, onViewProducts }) => {
  const headers = [
    { text: "Producto", value: "product" },
    { text: "Cantidad", value: "qty" },
    { text: "Precio", value: "price" },
  ];

  return (
    <React.Fragment>
      <Card key={purchase._id} className="px-4 py-2">
        <Row className="my-1 align-items-center justify-content-between">
          <Col xs={12} sm={8} className="py-2">
            Orden: <br />
            <div style={{ fontSize: ".8rem" }}>{purchase._id}</div>
          </Col>
          <Col xs={12} sm={4} md={3} className="text-end">
            <Button
              color="primary"
              size="sm"
              style={{ width: "100%" }}
              onClick={() => onViewProducts(purchase._id)}
            >
              Lista de productos
            </Button>
          </Col>
        </Row>
        <Row className="my-1 align-items-center justify-content-center">
          <Col xs={6} sm={6} md={3} className="py-2 text-center">
            <strong style={{ fontSize: "1rem" }}>Fecha:</strong>
            <br />
            <div style={{ fontSize: ".8rem" }}>
              {new Date(purchase.createdAt).toLocaleDateString()}
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} className="py-2 text-center">
            <strong style={{ fontSize: "1rem" }}>Estatus:</strong>
            <br />
            <div style={{ fontSize: ".8rem" }}>{purchase.status}</div>
          </Col>
          <Col xs={6} sm={6} md={3} className="py-2 text-center">
            <strong style={{ fontSize: "1rem" }}>No. de guía:</strong>
            <br />
            <div style={{ fontSize: ".8rem" }}>{purchase.trackingNumber}</div>
          </Col>
          <Col xs={6} sm={6} md={3} className="py-2 text-center">
            <a href={purchase.link}>Link de paquetería</a>
          </Col>
          <Col className="col-12 my-4">
            <div className="table-responsive table-card">
              <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                <thead className="text-muted table-light">
                  <tr>
                    {headers.map((header) => (
                      <th key={header.value} scope="col">
                        {header.text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {purchase.purchasedProducts.length ? (
                    (purchase.purchasedProducts || []).map((item, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td key={header.value + "-" + index}>
                            {item[header.value]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="100%" className="text-center">
                        No hay registros existentes
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
          <Col className="col-12 text-end">
            <strong>Paquetería: </strong>$
            {parseFloat(purchase.shippingFee).toFixed(2)}
          </Col>
          <Col className="col-12 text-end">
            <strong>Total: </strong>$
            {parseFloat(purchase.shippingFee).toFixed(2)}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default PurchaseCard;
