import React from "react";
import { Row, Col } from "reactstrap";
import "./OperatorsRow.css";
//sales operators
import mobileInternet from "@images/panda/ventas/operador-mobile.png";
import internetInHome from "@images/panda/ventas/operador-internet-en-casa.png";
import mobileOperatorInternet from "@images/panda/ventas/operador-internet-movil.png";

const operators = [mobileInternet, internetInHome, mobileOperatorInternet];

const OperatorsRow = (props) => {
  return (
    <React.Fragment>
      <Row className="justify-content-center mt-4">
        {operators.map((operator, index) => (
          <Col key={index} sm={4} md={4} xs={6} className="text-center">
            <img
              className="operator"
              style={{ marginBottom: "1rem" }}
              width="80%"
              src={operator}
              onClick={() => props.onSelectOperator(operator)}
            />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default OperatorsRow;
