import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { useMyAdresses } from "@Components/Hooks/AddressHooks";
import { useCartResume } from "../../../Components/Hooks/ShoppingCartHooks";

const ResumeSection = () => {
  const { addressesList } = useMyAdresses();
  const { cartResume } = useCartResume();

  const onPayCart = () => {};

  return (
    <React.Fragment>
      <Card className="p-2" style={{ minHeight: "40vh" }}>
        <CardBody>
          <Row className="justify-content-center">
            <Col
              xs={11}
              md={9}
              className="text-center border-bottom border-primary"
            >
              <h4 className="text-primary">Resumen de la compra</h4>
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="col-12 py-1">
              <Label className="form-label fw-bold">Dirección de envío</Label>
              <select className="form-select">
                <option value disabled selected>
                  Seleccione una dirección
                </option>
                {addressesList.map((address) => (
                  <option key={address.id} value={address.id}>
                    {address.address}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="col-12 py-1">
              <Link
                className="btn btn-primary w-100 py-2"
                to="/marketplace/mis-direcciones"
              >
                Editar mis direcciones
              </Link>
            </Col>
            <Col className="col-12 py-4 my-4 border-primary border-top border-bottom">
              <Row>
                <Col className="col-6">
                  {cartResume.productsQty}
                  {cartResume.productsQty == 1 ? " artículo" : " artículos"}
                </Col>
                <Col className="col-6">
                  ${parseFloat(cartResume.productsTotal).toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col className="col-6">Paquetería</Col>
                <Col className="col-6">
                  ${parseFloat(cartResume.shippingCost).toFixed(2)}
                </Col>
              </Row>
              <Row className="text-primary">
                <Col className="col-6">Paquetería</Col>
                <Col className="col-6">
                  ${parseFloat(cartResume.shippingCost).toFixed(2)}
                </Col>
              </Row>
            </Col>
            <Col className="col-12 py-1">
              Tu saldo después del pago: $
              {parseFloat(cartResume.totalAfterPayment).toFixed(2)}
            </Col>
            <Col className="col-12 py-1 my-3">
              <button
                className="btn btn-primary w-100 py-2"
                onClick={onPayCart}
              >
                Pagar
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResumeSection;
