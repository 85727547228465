import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import androidImg from "@images/panda/profile/logo-android.png";
import webImg from "@images/panda/profile/logo-web.png";
import AddAndroidDeviceModal from "./AddAndroidDeviceModal";
import { ApplicationsTable } from "./ApplicationsTable";
import axios from "axios";
import { api } from "../../../../config";
import { errorAlert, successAlert } from "../../../../helpers/alerts";

const ApplicationsTab = () => {
  const [isAndroidDeviceModalOpen, setIsAndroidDeviceModalOpen] =
    useState(false);
  const onAddWebPage = () => {};
  const onAddAndroidDevice = () => {
    setIsAndroidDeviceModalOpen(true);
  };

  const [phone, setPhone] = useState("");

  const addPhone = async () => {
    try {
      const response = await axios.post(`${api.API_URL}/app/sendAddChild`, {
        msisdntoadd: phone,
      });

      successAlert("Agregado", "Invitación enviada correctamente");
    } catch (error) {
      errorAlert("Error", "No es posible agregar este número");
    }
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xs={12} sm={10}>
          <Row className="fs-1-1 text-primary px-2 mb-4">Agregar Telefono</Row>
          <p>Importante</p>
          <ul>
            <li>
              El numero a agregar recibirá un sms con la liga para aceptar
            </li>
            <li>
              El numero tiene 2 horas apartir de que usted haga clic en el boton
              "Agregar" para que acepte la invitación
            </li>
            <li>
              El numero aparecerá en su lista una vez que acepte la invitación
            </li>
            <li>Usted podra interactuar como este numero</li>
            <li>
              Usted podra generar facturas de este numero pero los datos
              fiscales seran los de usted
            </li>
          </ul>

          <Row className="justify-content-center gap-3">
            <Col xs={12} sm={10}>
              <Input
                type="number"
                placeholder="Número a agregar"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>

            <Col
              xs={12}
              sm={10}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="btn btn-primary"
                style={{ width: "50%" }}
                onClick={addPhone}
              >
                Agregar
              </button>
            </Col>
          </Row>

          {isAndroidDeviceModalOpen && (
            <AddAndroidDeviceModal
              isOpen={isAndroidDeviceModalOpen}
              onClose={() => setIsAndroidDeviceModalOpen(false)}
            />
          )}
          <Row className="fs-1-1 text-primary px-2 mt-5 mb-2">
            Lista de números
          </Row>
          <ApplicationsTable />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApplicationsTab;
