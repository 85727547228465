export const balances = [{
  "id": 1,
  "first_name": "Peggi",
  "last_name": "Gooke",
  "user_name": "pgooke0",
  "saldoInicial": 4383,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 172
}, {
  "id": 2,
  "first_name": "Erda",
  "last_name": "Ivasechko",
  "user_name": "eivasechko1",
  "saldoInicial": 7949,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7276
}, {
  "id": 3,
  "first_name": "Freddy",
  "last_name": "Dockery",
  "user_name": "fdockery2",
  "saldoInicial": 8492,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 9264
}, {
  "id": 4,
  "first_name": "Wendie",
  "last_name": "Celler",
  "user_name": "wceller3",
  "saldoInicial": 7104,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 2706
}, {
  "id": 5,
  "first_name": "Conn",
  "last_name": "Vear",
  "user_name": "cvear4",
  "saldoInicial": 7359,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 4514
}, {
  "id": 6,
  "first_name": "Allison",
  "last_name": "Yarn",
  "user_name": "ayarn5",
  "saldoInicial": 8208,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1445
}, {
  "id": 7,
  "first_name": "Andy",
  "last_name": "Crooke",
  "user_name": "acrooke6",
  "saldoInicial": 4809,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 3359
}, {
  "id": 8,
  "first_name": "Stacia",
  "last_name": "Merigot",
  "user_name": "smerigot7",
  "saldoInicial": 6059,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1911
}, {
  "id": 9,
  "first_name": "Thomasine",
  "last_name": "Argo",
  "user_name": "targo8",
  "saldoInicial": 5409,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 80
}, {
  "id": 10,
  "first_name": "Maurits",
  "last_name": "Bridie",
  "user_name": "mbridie9",
  "saldoInicial": 1383,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7853
}, {
  "id": 11,
  "first_name": "Sullivan",
  "last_name": "Cuncliffe",
  "user_name": "scuncliffea",
  "saldoInicial": 2386,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1215
}, {
  "id": 12,
  "first_name": "Loralyn",
  "last_name": "Liff",
  "user_name": "lliffb",
  "saldoInicial": 8025,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 3649
}, {
  "id": 13,
  "first_name": "Julianna",
  "last_name": "Cobello",
  "user_name": "jcobelloc",
  "saldoInicial": 6480,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 9379
}, {
  "id": 14,
  "first_name": "Anabal",
  "last_name": "Mauger",
  "user_name": "amaugerd",
  "saldoInicial": 7576,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 3223
}, {
  "id": 15,
  "first_name": "Tanhya",
  "last_name": "Borless",
  "user_name": "tborlesse",
  "saldoInicial": 3023,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 567
}, {
  "id": 16,
  "first_name": "Shena",
  "last_name": "Faithfull",
  "user_name": "sfaithfullf",
  "saldoInicial": 2807,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 6837
}, {
  "id": 17,
  "first_name": "Adria",
  "last_name": "Dominec",
  "user_name": "adominecg",
  "saldoInicial": 6375,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 704
}, {
  "id": 18,
  "first_name": "Wrennie",
  "last_name": "Bourbon",
  "user_name": "wbourbonh",
  "saldoInicial": 7278,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7846
}, {
  "id": 19,
  "first_name": "Angelle",
  "last_name": "Ballefant",
  "user_name": "aballefanti",
  "saldoInicial": 8436,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1708
}, {
  "id": 20,
  "first_name": "Jameson",
  "last_name": "Luckcock",
  "user_name": "jluckcockj",
  "saldoInicial": 797,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 324
}, {
  "id": 21,
  "first_name": "Sandra",
  "last_name": "Goulbourn",
  "user_name": "sgoulbournk",
  "saldoInicial": 6208,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 6918
}, {
  "id": 22,
  "first_name": "Caria",
  "last_name": "Queree",
  "user_name": "cquereel",
  "saldoInicial": 293,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 6600
}, {
  "id": 23,
  "first_name": "Eliot",
  "last_name": "Caplin",
  "user_name": "ecaplinm",
  "saldoInicial": 1996,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1104
}, {
  "id": 24,
  "first_name": "Sarita",
  "last_name": "Dirkin",
  "user_name": "sdirkinn",
  "saldoInicial": 5071,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1123
}, {
  "id": 25,
  "first_name": "Dur",
  "last_name": "Szapiro",
  "user_name": "dszapiroo",
  "saldoInicial": 9642,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 5069
}, {
  "id": 26,
  "first_name": "Kile",
  "last_name": "Breslau",
  "user_name": "kbreslaup",
  "saldoInicial": 7196,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1030
}, {
  "id": 27,
  "first_name": "Nerissa",
  "last_name": "Satterfitt",
  "user_name": "nsatterfittq",
  "saldoInicial": 1228,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 2241
}, {
  "id": 28,
  "first_name": "Helli",
  "last_name": "Evert",
  "user_name": "hevertr",
  "saldoInicial": 6292,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 5564
}, {
  "id": 29,
  "first_name": "Joscelin",
  "last_name": "Solly",
  "user_name": "jsollys",
  "saldoInicial": 2217,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 5282
}, {
  "id": 30,
  "first_name": "Chery",
  "last_name": "Wolfart",
  "user_name": "cwolfartt",
  "saldoInicial": 3272,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 4698
}, {
  "id": 31,
  "first_name": "Kevin",
  "last_name": "Lang",
  "user_name": "klangu",
  "saldoInicial": 3072,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7119
}, {
  "id": 32,
  "first_name": "Domenico",
  "last_name": "Petrazzi",
  "user_name": "dpetrazziv",
  "saldoInicial": 2093,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7797
}, {
  "id": 33,
  "first_name": "Greta",
  "last_name": "Van Der Weedenburg",
  "user_name": "gvanderweedenburgw",
  "saldoInicial": 1931,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 8660
}, {
  "id": 34,
  "first_name": "Brook",
  "last_name": "Graysmark",
  "user_name": "bgraysmarkx",
  "saldoInicial": 4107,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 3874
}, {
  "id": 35,
  "first_name": "Danella",
  "last_name": "Bownass",
  "user_name": "dbownassy",
  "saldoInicial": 8660,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 4150
}, {
  "id": 36,
  "first_name": "Glen",
  "last_name": "Yann",
  "user_name": "gyannz",
  "saldoInicial": 636,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7520
}, {
  "id": 37,
  "first_name": "Carny",
  "last_name": "Gobel",
  "user_name": "cgobel10",
  "saldoInicial": 7340,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 8036
}, {
  "id": 38,
  "first_name": "Ossie",
  "last_name": "Hebson",
  "user_name": "ohebson11",
  "saldoInicial": 4149,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 3556
}, {
  "id": 39,
  "first_name": "Conway",
  "last_name": "Simmell",
  "user_name": "csimmell12",
  "saldoInicial": 1119,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 8114
}, {
  "id": 40,
  "first_name": "Drucie",
  "last_name": "Hammerberger",
  "user_name": "dhammerberger13",
  "saldoInicial": 9056,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 1718
}, {
  "id": 41,
  "first_name": "Courtnay",
  "last_name": "Mousby",
  "user_name": "cmousby14",
  "saldoInicial": 4036,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 8745
}, {
  "id": 42,
  "first_name": "Daron",
  "last_name": "Hardwin",
  "user_name": "dhardwin15",
  "saldoInicial": 6221,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 4642
}, {
  "id": 43,
  "first_name": "Pru",
  "last_name": "Cridge",
  "user_name": "pcridge16",
  "saldoInicial": 1583,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7741
}, {
  "id": 44,
  "first_name": "Henryetta",
  "last_name": "Ives",
  "user_name": "hives17",
  "saldoInicial": 4239,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 581
}, {
  "id": 45,
  "first_name": "Gussy",
  "last_name": "Pallent",
  "user_name": "gpallent18",
  "saldoInicial": 7554,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 4580
}, {
  "id": 46,
  "first_name": "Rip",
  "last_name": "McElwee",
  "user_name": "rmcelwee19",
  "saldoInicial": 3611,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 5102
}, {
  "id": 47,
  "first_name": "Carole",
  "last_name": "Stonebridge",
  "user_name": "cstonebridge1a",
  "saldoInicial": 811,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 7155
}, {
  "id": 48,
  "first_name": "Emiline",
  "last_name": "Viscovi",
  "user_name": "eviscovi1b",
  "saldoInicial": 6973,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 5599
}, {
  "id": 49,
  "first_name": "Deloria",
  "last_name": "Petegree",
  "user_name": "dpetegree1c",
  "saldoInicial": 9519,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 8142
}, {
  "id": 50,
  "first_name": "Ive",
  "last_name": "Andriolli",
  "user_name": "iandriolli1d",
  "saldoInicial": 7078,
  "saldoRecibido": 0,
  "venta": 0,
  "saldoEnviado": 0,
  "residual": 0,
  "saldoActual": 3700
}]
