import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../../config";
import { errorAlert } from "../../helpers/alerts";
import { authProvider } from "./authProvider";

const ProductsProvider = () => {
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVal, setIsLoadingVal] = useState(false);

  const { getHistorial } = authProvider();

  const getAllProducts = async () => {
    try {
      const response = await axios.get(`${api.API_URL}/app/getAllProducts`);

      setProducts(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const InitPaymentPP = async (payload) => {
    setIsLoading(true);
    try {
      let response = await axios
        .post(`${api.API_URL}/app/initPPPayment`, payload)
        .catch(function (error) {
          console.log("error1");
          if (error.response) {
            console.log(error.response);
            return false;
          } else if (error.request) {
            console.log("error2");
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
            return false;
          } else {
            console.log("error3");
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            return false;
          }
        });

      if (response && response.response) {
        setIsLoading(false);
        return response.response;
      }

      // '/payment'
      return false;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return false;
    }
  };

  const ValPaymentPP = async (payload) => {
    setIsLoadingVal(true);
    try {
      let response = await axios
        .post(`${api.API_URL}/paypal/validatev2`, payload)
        .catch(function (error) {
          console.log("error");
          if (error.response) {
            console.log("error1");
            console.log(error.response);
            return false;
          } else if (error.request) {
            console.log("error2");
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
            return false;
          } else {
            console.log("error3");
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            return false;
          }
        });
      if (response && response.data && response.data.status == 200) {
        setIsLoadingVal(false);
        return true;
      }
      return false;
    } catch (err) {
      setIsLoadingVal(false);
      console.log(err);
      return false;
    }
  };

  const InitPaymentMP = async (payload) => {
    setIsLoading(true);
    try {
      let response = await axios
        .post(`${api.API_URL}/app/initMPPayment`, payload)
        .catch(function (error) {
          console.log("error1");
          if (error.response) {
            console.log(error.response);
            return false;
          } else if (error.request) {
            console.log("error2");
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
            return false;
          } else {
            console.log("error3");
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            return false;
          }
        });

      if (response && response.response) {
        setIsLoading(false);
        return response;
      }

      // '/payment'
      return false;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return false;
    }
  };

  const paymentStripe = async (payload) => {
    setIsLoading(true);
    try {
      return await axios
        .post(`${api.API_URL}/app/payment`, payload)
        .then((res)=>{
          setIsLoading(false);
          return res
        })
        .catch(function (error) {
          setIsLoading(false);
          errorAlert("error", error);
          return false
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return false;
    }
  };

  const AddFactura = async (payload) => {
    setIsLoading(true);
    try {
      let response = await axios
        .post(`${api.API_URL}/app/generateFactura`, payload)
        .catch(function (error) {
          errorAlert(
            "Error",
            "Este RFC del receptor no existe en la lista de RFC inscritos no cancelados del SAT"
          );
        });

      if (response && response.result.datas) {
        // generate factura pdf

        var base64Data = response.result.datas;
        var binaryData = atob(base64Data);
        var arrayBuffer = new ArrayBuffer(binaryData.length);
        var uint8Array = new Uint8Array(arrayBuffer);

        for (var i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }

        var blob = new Blob([uint8Array], { type: response.result.mimetype });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = response.result.name;
        link.click();
      }
      setIsLoading(false);
      getHistorial(payload.msisdn);

      // '/payment'
      return false;
    } catch (err) {
      setIsLoading(false);
      getHistorial(payload.msisdn);
      console.log(err);
      return false;
    }
  };

  return {
    products,
    getAllProducts,
    isLoadingPPPaymentConfig: isLoading,
    InitPaymentPP,
    ValPaymentPP,
    isLoadingPPPaymentVal: isLoadingVal,
    InitPaymentMP,
    paymentStripe,
    AddFactura,
  };
};

export { ProductsProvider };
